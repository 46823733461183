import MerchantDevices from "./MerchantDevices";

export default function MerchantDevicesPage() {
  return (
    <>
      <div className={"p-3 md:p-0"}>
        <div className="flex flex-col px-2 md:px-0 w-full pb-2">
          <h1 className="font-bold text-xl mb-4">Devices</h1>
          <MerchantDevices />
        </div>
      </div>
    </>
  );
}
