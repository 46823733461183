import { LogoutIcon, MailIcon, OfficeBuildingIcon, UserIcon } from '@heroicons/react/outline';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { BeakerIcon } from '@heroicons/react/solid';
import useAuthContext from '../../hooks/auth-context.hook';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../common/components/ui/dropdown-menu";

export function Home() {
  const auth = useAuthContext();
  const { user } = auth.getUser();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="w-full bg-white">
        <div className="sticky top-0 w-full z-10 bg-white shadow-sm backdrop-blur-sm bg-white/80">
          <div className="max-w-7xl mx-auto">
            <div className="flex h-16 items-center justify-between px-4">
              {/* Logo */}
              <div className="flex-shrink-0">
                <img
                  className="h-8 w-auto"
                  src={require('../../assets/qbon_long_logo.png')}
                  alt="qbon-logo-dashboard"
                />
              </div>

              {/* User Menu */}
              <div className="flex items-center gap-4">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <button className="flex items-center gap-2 rounded-full bg-white px-3 py-1.5 text-sm transition-colors hover:bg-zinc-50 border border-zinc-200">
                      <span className="font-medium text-zinc-700">
                        {user.email}
                      </span>
                      <UserIcon className="h-4 w-4 text-zinc-600" />
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-48">
                    {user.type === 'SUPER_ADMIN' && (
                      <DropdownMenuItem onClick={() => navigate('/admin/users')}>
                        <div className="flex items-center cursor-pointer">
                          <BeakerIcon className="mr-2 h-4 w-4" />
                          Admin Dashboard
                        </div>
                      </DropdownMenuItem>
                    )}
                    {!location.pathname.includes('invites') && (
                      <DropdownMenuItem onClick={() => navigate('/home/invites')}>
                        <div className="flex items-center cursor-pointer">
                          <MailIcon className="mr-2 h-4 w-4" />
                          My Invites
                        </div>
                      </DropdownMenuItem>
                    )}
                    {!location.pathname.includes('merchants') && (
                      <DropdownMenuItem onClick={() => navigate('/home/merchants')}>
                        <div className="flex items-center cursor-pointer">
                          <OfficeBuildingIcon className="mr-2 h-4 w-4" />
                          My Merchants
                        </div>
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuItem onClick={() => auth.logOut()}>
                      <div className="flex items-center cursor-pointer text-red-600">
                        <LogoutIcon className="mr-2 h-4 w-4" />
                        Log Out
                      </div>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-4 py-4">
          <Outlet />
        </div>
      </div>
    </>
  );
}
