import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useEffect, useMemo, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Button } from "../components/ui/button";

export interface PageInfo {
  pageNumber: number;
  pageSize: number;
  hasPrevious: boolean;
  hasNext: boolean;
  totalSize: number;
}

export interface PaginationProps {
  pageInfo: PageInfo;
  onChange: (info: PageInfo) => void;
}

export default function Pagination(props: PaginationProps) {
  const { pageInfo, onChange } = props;
  const [currentPage, setCurrentPage] = useState(pageInfo.pageNumber);

  const totalPages = useMemo(() => {
    return Math.ceil(pageInfo.totalSize / pageInfo.pageSize);
  }, [pageInfo.totalSize, pageInfo.pageSize]);

  const pageNumbers = useMemo(() => {
    const pages = [];
    const maxVisiblePages = 5;
    const halfVisible = Math.floor(maxVisiblePages / 2);

    let start = Math.max(currentPage - halfVisible, 1);
    let end = Math.min(start + maxVisiblePages - 1, totalPages);

    if (end - start + 1 < maxVisiblePages) {
      start = Math.max(end - maxVisiblePages + 1, 1);
    }

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  }, [currentPage, totalPages]);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
      onChange({ ...pageInfo, pageNumber: totalPages });
    }
  }, [pageInfo.pageSize, currentPage, onChange, pageInfo, totalPages]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    onChange({ ...pageInfo, pageNumber: page });
  };

  const handlePageSizeChange = (value: string) => {
    const newPageSize = parseInt(value);
    const newTotalPages = Math.ceil(pageInfo.totalSize / newPageSize);
    const newPageNumber = Math.min(currentPage, newTotalPages);

    onChange({
      ...pageInfo,
      pageSize: newPageSize,
      pageNumber: newPageNumber,
    });
  };

  const startItem = (pageInfo.pageNumber - 1) * pageInfo.pageSize + 1;
  const endItem = Math.min(
    pageInfo.pageNumber * pageInfo.pageSize,
    pageInfo.totalSize
  );

  return (
    <div className="flex flex-col gap-4 sm:flex-row items-center justify-between px-2 py-4">
      <div className="text-sm text-muted-foreground">
        Showing <span className="font-medium">{startItem}</span> to{" "}
        <span className="font-medium">{endItem}</span> of{" "}
        <span className="font-medium">{pageInfo.totalSize}</span> results
      </div>

      <div className="flex items-center gap-2">
        <div className="flex items-center gap-1">
          <Button
            variant="outline"
            size="icon"
            disabled={!pageInfo.hasPrevious}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>

          {pageNumbers.map((page) => (
            <Button
              key={page}
              variant={page === currentPage ? "default" : "outline"}
              size="icon"
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Button>
          ))}

          <Button
            variant="outline"
            size="icon"
            disabled={!pageInfo.hasNext}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>

        <Select
          value={pageInfo.pageSize.toString()}
          onValueChange={handlePageSizeChange}
        >
          <SelectTrigger className="w-[70px]">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {[10, 20, 50, 100].map((size) => (
              <SelectItem key={size} value={size.toString()}>
                {size}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
