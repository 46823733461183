import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../lib/utils";
import { Button } from "../components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import { Label } from "../components/ui/label";
import HelpTip from "./HelpTip/HelpTip";
import { CommandList } from "cmdk";

export interface ComboxboxProps<T> {
  label?: string;
  value: T | null | undefined;
  options: T[];
  displayKey?: keyof T;
  idKey?: keyof T;
  className?: string;
  onChange: (value: T) => void;
  helpTip?: string;
  relative?: boolean;
  icon?: React.ReactNode;
  required?: boolean;
}

export default function Combobox<T>(props: ComboxboxProps<T>) {
  const {
    value,
    options,
    displayKey,
    idKey,
    className,
    onChange,
    label,
    helpTip,
    icon,
    required,
  } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <div className={cn("relative", className)}>
      {label && (
        <div className="flex items-center gap-2 mb-3">
          {icon && <div className="w-4 h-4">{icon}</div>}
          <Label
            htmlFor={label}
            className={cn(
              "text-sm font-bold",
              required ? "text-[#fb3310]" : "text-gray-900"
            )}
          >
            {required && "*"} {label}
          </Label>
          {helpTip && <HelpTip message={helpTip} />}
        </div>
      )}

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between h-[36px]"
          >
            {value && displayKey
              ? String(value[displayKey])
              : value
              ? String(value)
              : "Select..."}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[--radix-popover-trigger-width] p-0">
          <Command>
            <CommandInput placeholder="Search..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option: T) => (
                  <CommandItem
                    key={
                      idKey && option[idKey]
                        ? String(option[idKey])
                        : String(option)
                    }
                    value={
                      displayKey && option[displayKey]
                        ? String(option[displayKey])
                        : String(option)
                    }
                    onSelect={() => {
                      onChange(option);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value === option ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {displayKey && option[displayKey]
                      ? String(option[displayKey])
                      : String(option)}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
