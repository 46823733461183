import { useState } from 'react';
import { useRaffles } from '../../../hooks/raffle.hook';
import useAuthContext from '../../../hooks/auth-context.hook';
import Table, { TableColumn } from '../../../common/library/Table';
import { RaffleEntry } from '../../../models/raffle';
import Button from '../../../common/library/Button';
import Pagination from '../../../common/library/Pagination';
import Input from '../../../common/library/Input';
import { ErrorMessage } from '../../../common/library/ErrorMessage';

interface Props {
  raffleId: number;
}

export default function RaffleEntries({ raffleId }: Props) {
  const auth = useAuthContext();
  const { user, merchant } = auth.getMerchantAndUser();

  const [customEntryFields, setCustomEntryFields] =
    useState<{ name: string, email: string }>({ name: '', email: '' });
  const [errors, setErrors] = useState<string[]>([]);
  const [pageInfo, setPageInfo] = useState<{
    page: number,
    pageSize: number
  }>({
    page: 0,
    pageSize: 10,
  });

  const { useMerchantRaffleEntries, useCreateCustomEntryMutation } = useRaffles();
  const {
    isSuccess,
    data,
    isLoading,
    refetch,
  } = useMerchantRaffleEntries(user.id, merchant.id, raffleId, pageInfo.page, pageInfo.pageSize);

  const createCustomEntryMutation = useCreateCustomEntryMutation(merchant.id, raffleId,
    () => {
      refetch();
      setPageInfo({ page: 0, pageSize: 10 });
      setCustomEntryFields({ name: '', email: '' });
    }, (error: any) => {
      setErrors(error.message);
    });

  const columns: TableColumn<RaffleEntry>[] = [
    {
      label: 'Name',
      format: (val) => <>{val.name}</>,
    },
    {
      label: 'Email',
      format: (val) => <>{val.email}</>,
    },
    {
      label: 'Entry Number',
      format: (val) => <>{val.entryNumber}</>,
    },
  ];

  console.log(data);

  return (
    <>
      {errors.length > 0 && <div className="py-2">
        <ErrorMessage message={errors} />
      </div>}
      <div className={"w-full lg:w-[550px]"}>
        <div className={'py-2'}>
          <Input label={'Custom Entry Name'} value={customEntryFields.name}
                 onChange={(v) => setCustomEntryFields({ ...customEntryFields, name: v })} />
        </div>
        <div className={'py-2'}>
          <Input label={'Custom Entry Email'} value={customEntryFields.email}
                 onChange={(v) => setCustomEntryFields({ ...customEntryFields, email: v })} />
        </div>
        <div className={'py-2'}>
          <Button buttonWidth={'full'} label={'Create Entry'}
                  onClick={() => createCustomEntryMutation.mutate(customEntryFields)}
                  disabled={customEntryFields.email === '' || customEntryFields.name === ''} variant={'outline'} />
        </div>
      </div>
      <div className="w-full pt-6">
        <Table
          loading={isLoading}
          columns={columns}
          data={isSuccess ? data.data : []}
        />
        {isSuccess && <Pagination
          pageInfo={{
            pageNumber: pageInfo.page + 1,
            pageSize: pageInfo.pageSize,
            totalSize: data.totalSize,
            hasPrevious: data.hasPrevious,
            hasNext: data.hasNext,
          }}
          onChange={(info) =>
            setPageInfo({
              page: Math.floor(info.pageNumber - 1),
              pageSize: info.pageSize,
            })
          }
        />}
      </div>
    </>
  );

}



