import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useMetrics } from '../../hooks/metrics.hook';
import { MetricType } from './type';

interface Props {
  metricType: MetricType;
  fromDate: Date;
  toDate: Date;
  primaryColor: string;
}

export function SystemMetricView({ primaryColor, metricType, fromDate, toDate }: Props) {

  const meta = () => {
    switch (metricType) {
      case ('MERCHANT_CREATED'): {
        return { title: 'Merchants created', param: 'systemMetricValue=MERCHANT_CREATED' };
      }
      case ('USER_CREATED'): {
        return { title: 'Users created', param: 'systemMetricValue=MERCHANT_CREATED' };
      }
      default: {
        return { title: 'No metric available', param: '' };
      }
    }
  };

  const { useSystemMetrics } = useMetrics();
  const { data, isSuccess, isLoading } = useSystemMetrics(
    metricType,
    fromDate,
    toDate,
  );

  return (
    <>
      <div className="font-bold">{meta().title}</div>
      {!isLoading &&
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={isSuccess ? data.timeseries : []}
            margin={{
              top: 5,
              right: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="count"
              stroke={primaryColor}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>}
    </>
  );
}