import useAuthContext from "../../../hooks/auth-context.hook";
import useMerchantTutorial from "../../../hooks/merchant-tutorial.hook";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Spinner from "../../../common/library/Spinner";
import { LogoAndCoverTutorial } from "./LogoAndCoverTutorial";
import CustomLinksTutorial from "./CustomLinksTutorial";
import AddDevicesTutorial from "./AddDevicesTutorial";
import SubscribeTutorial from "./SubscribeTutorial";
import GeofenceTutorial from "./GeofenceTutorial";
import CreateQbonTutorial from "./CreateQbonTutorial";
import useMerchants from "../../../hooks/merchants.hook";
import Button from "../../../common/library/Button";
import { motion } from "framer-motion";
import { cn } from "../../../lib/utils";

const StepIndicator = ({ currentStep }: { currentStep: number }) => {
  const steps = [
    "Logo",
    "Links",
    "Devices",
    "Subscribe",
    "Geofence",
    "Create Qbon",
  ];

  return (
    <div className="space-y-2 pb-4">
      <div className="relative">
        <div className="absolute top-4 left-0 w-full h-0.5 bg-gray-200" />
        <motion.div
          className="absolute top-4 left-0 h-0.5 bg-primary"
          initial={{ width: `${(currentStep - 1) * 20}%` }}
          animate={{ width: `${(currentStep - 1) * 20}%` }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        />
        <div className="relative flex justify-between">
          {steps.map((label, index) => {
            const stepNumber = index + 1;
            const isActive = currentStep === stepNumber;
            const isComplete = currentStep > stepNumber;

            return (
              <motion.div
                key={label}
                className="flex flex-col items-center"
                initial={false}
                animate={{ scale: isActive ? 1.1 : 1 }}
                transition={{ duration: 0.2 }}
              >
                <div
                  className={cn(
                    "w-8 h-8 rounded-full border-2 flex items-center justify-center text-sm font-medium transition-all duration-300 relative z-10",
                    isActive && "border-primary bg-primary text-white",
                    isComplete && "border-primary bg-white text-primary",
                    !isActive &&
                      !isComplete &&
                      "border-gray-300 bg-white text-gray-500"
                  )}
                >
                  {stepNumber}
                </div>
                <span className="mt-2 text-xs font-medium text-gray-600">
                  {label}
                </span>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default function TutorialPage() {
  const navigate = useNavigate();
  const auth = useAuthContext();
  const { user, merchant: contextMerchant } = auth.getMerchantAndUser();
  const { useMerchantQuery } = useMerchants();
  const { data: merchant } = useMerchantQuery(contextMerchant.id);
  const { useUpdateMerchantTutorialMutation, useMerchantTutorialQuery } =
    useMerchantTutorial();
  const {
    data: tutorialState,
    isLoading,
    isError,
    refetch,
  } = useMerchantTutorialQuery(contextMerchant.id);
  const { mutate: updateMerchantTutorial } = useUpdateMerchantTutorialMutation(
    contextMerchant.id,
    () => {
      refetch();
    }
  );

  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    if (isError) {
      navigate("dashboard/metrics");
    }

    if (!isLoading && tutorialState) {
      setStep(tutorialState.step);
    }

    if (
      tutorialState &&
      tutorialState.step === 5 &&
      merchant &&
      merchant.status === "UNSUBSCRIBED"
    ) {
      updateMerchantTutorial({ step: 4 });
      setStep(4);
    }

    if (tutorialState && tutorialState.completed) {
      navigate("/dashboard/profile");
    }

    if (step > 6) {
      updateMerchantTutorial({ completed: true });
    }
  }, [
    isError,
    tutorialState,
    isLoading,
    merchant,
    navigate,
    updateMerchantTutorial,
    step,
  ]);

  return (
    <div className="h-screen flex flex-col">
      <div className={"p-3 w-full lg:w-[550px] lg:m-auto flex-1 flex flex-col"}>
        <div className="flex-1">
          {isLoading ? (
            <div className="flex justify-center items-center">
              <Spinner width={16} height={16} color={"red"} />
            </div>
          ) : (
            <>
              {merchant && (
                <>
                  {step === 1 && (
                    <LogoAndCoverTutorial user={user} merchant={merchant} />
                  )}
                  {step === 2 && <CustomLinksTutorial merchant={merchant} />}
                  {step === 3 && <AddDevicesTutorial />}
                  {step === 4 && (
                    <SubscribeTutorial
                      merchant={merchant}
                      user={user}
                      onComplete={(acceptedSubscription) =>
                        updateMerchantTutorial({
                          completed: !acceptedSubscription,
                          step: 5,
                          acceptedSubscription,
                        })
                      }
                      onGoBack={() =>
                        updateMerchantTutorial({ step: step - 1 })
                      }
                    />
                  )}
                  {step === 5 && (
                    <GeofenceTutorial merchant={merchant} user={user} />
                  )}
                  {step === 6 && (
                    <CreateQbonTutorial
                      merchant={merchant}
                      user={user}
                      onComplete={() =>
                        updateMerchantTutorial({
                          completed: true,
                        })
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className="mt-auto">
          {step !== 4 && (
            <div className={"pt-6 flex gap-4 pb-4"}>
              {step !== 6 && (
                <Button
                  label={"Exit"}
                  buttonWidth={"full"}
                  onClick={() => auth.exitMerchant()}
                  variant={"outline"}
                />
              )}
              {step > 1 && (
                <Button
                  buttonWidth={"full"}
                  label={"Go Back"}
                  onClick={() => updateMerchantTutorial({ step: step - 1 })}
                  variant={"outline"}
                />
              )}
              {step !== 6 && (
                <Button
                  buttonWidth={"full"}
                  label={"Continue"}
                  onClick={() => updateMerchantTutorial({ step: step + 1 })}
                />
              )}
            </div>
          )}
          <StepIndicator currentStep={step} />
        </div>
      </div>
    </div>
  );
}
