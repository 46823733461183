import React, { useCallback, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar";
import { Camera } from "lucide-react";
import ImageCropModal from "../ImageCropper";

export default function ImageCircleUpload({
  imageUrl,
  onSave,
}: {
  imageUrl?: string;
  onSave?: (file: Blob) => void;
  saving: boolean;
}) {
  const [state, setState] = useState<{
    file: Blob | undefined;
    imagePreviewUrl: string | undefined;
    showCropModal: boolean;
  }>({
    file: undefined,
    imagePreviewUrl: imageUrl,
    showCropModal: false,
  });

  const photoUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setState((prevState) => ({
        ...prevState,
        file,
        imagePreviewUrl: url,
        showCropModal: true,
      }));
    }
  }, []);

  const handleCropComplete = useCallback((croppedBlob: Blob | null) => {
    if (croppedBlob && onSave) {
      onSave(croppedBlob);
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl = reader.result as string;
        setState((prevState) => ({
          ...prevState,
          file: croppedBlob,
          imagePreviewUrl: dataUrl,
          showCropModal: false,
        }));
      };
      reader.readAsDataURL(croppedBlob);
    } else {
      setState((prevState) => ({
        ...prevState,
        showCropModal: false,
      }));
    }
  }, [onSave]);

  return (
    <div className="flex flex-col items-center gap-4">
      <Avatar className="h-32 w-32 cursor-pointer relative group">
        <AvatarImage 
          src={state.imagePreviewUrl} 
          alt="Profile"
          className="object-cover"
        />
        <AvatarFallback className="bg-muted">
          <Camera className="h-8 w-8 text-muted-foreground" />
        </AvatarFallback>
        <div className="absolute inset-0 bg-black/60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity rounded-full">
          <label htmlFor="photo-upload" className="cursor-pointer">
            <Camera className="h-8 w-8 text-white" />
          </label>
        </div>
      </Avatar>

      <input
        type="file"
        id="photo-upload"
        accept="image/*"
        onChange={photoUpload}
        className="hidden"
      />

      {state.showCropModal && state.imagePreviewUrl && (
        <ImageCropModal
          src={state.imagePreviewUrl}
          aspect={[1, 1]}
          onComplete={handleCropComplete}
          onClose={() =>
            setState((prevState) => ({ ...prevState, showCropModal: false }))
          }
        />
      )}
    </div>
  );
}
