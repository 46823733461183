import { Merchant } from "../../../models/merchant";
import Input from "../../../common/library/Input";
import AddCustomLinkButton from "../../../common/library/AddCustomLinkButton";
import useMerchants from "../../../hooks/merchants.hook";
import useMerchantCustomLinks from "../../../hooks/merchants-custom-links.hook";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import TutorialDescriptionCard from "./TutorialDescriptionCard";

interface Props {
  merchant: Merchant;
}

export default function CustomLinksTutorial({ merchant }: Props) {
  const { useMerchantQuery } = useMerchants();
  const { data: editingMerchant, refetch } = useMerchantQuery(merchant.id);
  const { useCreateCustomLinkMutation, useDeleteCustomLinkMutation } =
    useMerchantCustomLinks();
  const createCustomLink = useCreateCustomLinkMutation(
    merchant.id,
    () => {
      toast("Custom Link created", { type: "success" });
      refetch();
    },
    (error) => toast(error.message[0], { type: "error" })
  );
  const deleteCustomLink = useDeleteCustomLinkMutation(
    merchant.id,
    () => {
      toast("Custom Link deleted", { type: "info" });
      refetch();
    },
    (error) => toast(error.message[0], { type: "error" })
  );

  return (
    <div className="space-y-6">
      <TutorialDescriptionCard
        title="Enhance Your Profile"
        description="Add custom links to your social media, website, menu, or review sites"
        icon={
          <>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 13C10.4295 13.5741 10.9774 14.0492 11.6066 14.3929C12.2357 14.7367 12.9315 14.9411 13.6467 14.9923C14.3618 15.0435 15.0799 14.9404 15.7482 14.6898C16.4164 14.4392 17.0186 14.0471 17.51 13.54L20.51 10.54C21.4647 9.54691 21.9852 8.22168 21.9732 6.84689C21.9612 5.4721 21.4175 4.15699 20.4451 3.18052C19.4727 2.20404 18.1597 1.65522 16.7849 1.63721C15.4101 1.61919 14.0831 2.13364 13.08 3.08997L11.25 4.91997"
                stroke="#4C1D95"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 11C13.5705 10.4259 13.0226 9.95083 12.3934 9.60707C11.7642 9.26331 11.0684 9.05889 10.3533 9.00768C9.63816 8.95646 8.92007 9.05964 8.25181 9.31023C7.58355 9.56082 6.98137 9.95294 6.48997 10.46L3.48997 13.46C2.53525 14.4531 2.01477 15.7783 2.02677 17.1531C2.03877 18.5279 2.58247 19.843 3.55483 20.8195C4.52719 21.796 5.84025 22.3448 7.21504 22.3628C8.58983 22.3808 9.91683 21.8664 10.92 20.91L12.74 19.09"
                stroke="#7C3AED"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="12" cy="12" r="3" fill="#FB3310" />
            </svg>
          </>
        }
      />

      {editingMerchant &&
        editingMerchant.customLinks &&
        editingMerchant.customLinks.length > 0 && (
          <motion.div
            className="space-y-3"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            {editingMerchant.customLinks.map((link, index) => (
              <motion.div
                key={link.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 + 0.3, duration: 0.3 }}
              >
                <Input
                  value={link.url}
                  label={link.label}
                  deletable
                  onDelete={() => deleteCustomLink.mutate(link.id)}
                />
              </motion.div>
            ))}
          </motion.div>
        )}

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        <AddCustomLinkButton
          onCreate={(label, url) => createCustomLink.mutate({ label, url })}
        />
      </motion.div>
    </div>
  );
}
