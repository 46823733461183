import { CheckCircleIcon, ExclamationCircleIcon, LocationMarkerIcon } from '@heroicons/react/outline';
import { Merchant } from '../models/merchant';
import { Card, CardContent, CardHeader } from "./components/ui/card";

interface MerchantCardProps {
  merchant: Merchant;
  displayImage?: boolean;
  displayAddress?: boolean;
  displayDistance?: boolean;
  displayYouAreHere?: boolean;
  onClick: () => void;
}

const MerchantCard: React.FC<MerchantCardProps> = ({
  merchant,
  displayImage = true,
  displayAddress = true,
  onClick,
}) => {
  return (
    <Card 
      onClick={onClick}
      className="w-full overflow-hidden transition-all hover:scale-[1.02] hover:cursor-pointer"
    >
      {displayImage && (
        <div className="relative h-[250px] w-full overflow-hidden">
          <img
            alt="Cover"
            src={
              merchant.coverImageUrl ||
              'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            }
            className="h-full w-full object-cover object-center"
          />
        </div>
      )}
      
      <CardHeader className="flex-row items-center gap-4 space-y-0">
        <img
          src={
            merchant.logoImageUrl ||
            'https://prod-werehere-images.s3.amazonaws.com/QBON.png'
          }
          className="h-12 w-12 rounded-full object-cover"
          alt="Logo"
        />
        <h3 className="font-semibold">{merchant.name}</h3>
      </CardHeader>

      <CardContent>
        {displayAddress && (
          <div className="flex items-center gap-2 text-sm text-muted-foreground">
            <LocationMarkerIcon className="h-4 w-4" />
            <span className="font-medium">{merchant.address.city}</span>
            <span className="text-[4px]">{'\u2B24'}</span>
            <span className="font-medium">{merchant.industry}</span>
            <span className="text-[4px]">{'\u2B24'}</span>
            
            {merchant.status === 'SUBSCRIBED' ? (
              <div className="flex items-center gap-1 text-green-600">
                <CheckCircleIcon className="h-4 w-4" />
                <span className="font-medium">Subscribed</span>
              </div>
            ) : (
              <div className="flex items-center gap-1 text-yellow-500">
                <ExclamationCircleIcon className="h-4 w-4" />
                <span className="font-medium">Not Subscribed</span>
              </div>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default MerchantCard;