import { useCallback } from "react";

export interface GeoLocation {
  placeId: string;
  addressline: string;
  city: string;
  county: string;
  state: string;
  country: string;
  postal_code: string;
  coordinates: {
    latitude: number,
    longitude: number
  }
}

export interface AutocompleteSuggestionsPrediction {
  description: string;
  place_id: string;
}

export interface AutocompleteSuggestions {
  predictions: AutocompleteSuggestionsPrediction[];
}

export default function useGoogleMaps() {
  const autocompleteService = useCallback(() => {
    return new google.maps.places.AutocompleteService();
  }, []);

  const geocoder = useCallback(() => {
    return new google.maps.Geocoder();
  }, []);

  const autocomplete = useCallback(
    (input: string): Promise<AutocompleteSuggestions> => {

      return autocompleteService()
        .getPlacePredictions({
          input,
        })
        .then((result) => {
          return result as AutocompleteSuggestions;
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject();
        });
    },
    [autocompleteService]
  );

  const geocode = useCallback((placeId: string): Promise<any> => {
    return geocoder()
      .geocode({ placeId })
      .then((res: google.maps.GeocoderResponse) => {
        if (res.results.length === 0) {
          return Promise.reject();
        }

        const address = res.results[0];
        const street_number = address.address_components.find((com) =>
          com.types.includes("street_number")
        );

        const route = address.address_components.find((com) =>
          com.types.includes("route")
        );

        const city = address.address_components.find((com) =>
          com.types.includes("locality")
        );

        const county = address.address_components.find((com) =>
          com.types.includes("administrative_area_level_2")
        );

        const state = address.address_components.find((com) =>
          com.types.includes("administrative_area_level_1")
        );

        const country = address.address_components.find((com) =>
          com.types.includes("country")
        );

        const postal_code = address.address_components.find((com) =>
          com.types.includes("postal_code")
        );

        return {
          addressline: `${street_number ? `${street_number?.long_name} ` : ``}${route?.long_name}`,
          city: city?.long_name,
          county: county?.long_name,
          state: state?.long_name,
          country: country?.long_name,
          postal_code: postal_code?.long_name,
          coordinates: {
            latitude: address.geometry.location.lat(),
            longitude: address.geometry.location.lng()
          }
        };
      });
  }, [geocoder]);

  return { autocomplete, geocode };
}
