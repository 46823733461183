import { useNavigate } from "react-router";
import { useCallback, useState } from "react";
import { Button } from "../../common/components/ui/button";
import { Input } from "../../common/components/ui/input";
import supabaseClient from "../../hooks/supabase";

export default function SignUp() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const signUp = useCallback(async () => {
    setIsLoading(true);
    setError(undefined);

    if (!agreedToTerms) {
      setError("You must agree to the privacy policy and terms of use.");
      setIsLoading(false);
      return;
    }

    if (!email || !firstName || !lastName || !password || !confirmPassword) {
      setError("Please fill in all fields");
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setIsLoading(false);
      return;
    }

    const result = await supabaseClient.auth.signUp({
      email,
      password,
      options: {
        data: {
          first_name: firstName,
          last_name: lastName,
          accepted_terms_and_conditions: true,
        },
      },
    });

    if (result.error) {
      setError(result.error.message);
    } else {
      navigate(`/verify?email=${email}`);
    }

    setIsLoading(false);
  }, [
    email,
    firstName,
    lastName,
    password,
    confirmPassword,
    agreedToTerms,
    navigate,
  ]);

  return (
    <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
        <div className="absolute inset-0">
          <img
            src={require("../../assets/cover_for_login.jpg")}
            alt="Login cover"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-black/50" /> {/* Dark overlay */}
        </div>
        <div className="relative z-20 flex items-center text-lg font-medium">
          <img
            src={require("../../assets/qbon_logo.png")}
            alt="qbon-logo"
            className="h-8 w-8 mr-2"
          />
          Qbon
        </div>
        <div className="relative z-20 mt-auto">
          <blockquote className="space-y-2">
            <p className="text-lg">
              "This platform has revolutionized how we manage our business."
            </p>
            <footer className="text-sm">Sofia Davis</footer>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              Create an account
            </h1>
            <p className="text-sm text-muted-foreground">
              Enter your details below to create your account
            </p>
          </div>

          <div className="grid gap-6">
            <div className="grid gap-4">
              {error && (
                <div className="text-sm font-medium text-destructive">
                  {error}
                </div>
              )}
              <div className="grid gap-2">
                <Input
                  id="email"
                  placeholder="name@example.com"
                  type="email"
                  autoCapitalize="none"
                  autoComplete="email"
                  autoCorrect="off"
                  disabled={isLoading}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  id="firstName"
                  placeholder="First Name"
                  type="text"
                  disabled={isLoading}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Input
                  id="lastName"
                  placeholder="Last Name"
                  type="text"
                  disabled={isLoading}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <Input
                  id="password"
                  placeholder="Password"
                  type="password"
                  disabled={isLoading}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Input
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  type="password"
                  disabled={isLoading}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={agreedToTerms}
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="terms"
                    className="text-sm text-muted-foreground"
                  >
                    I agree to the{" "}
                    <a
                      href="https://qbonapp.com/privacy-policy-2/"
                      className="underline"
                    >
                      privacy policy
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://qbonapp.com/terms-of-use/"
                      className="underline"
                    >
                      terms of use
                    </a>
                  </label>
                </div>
              </div>
              <Button disabled={isLoading} onClick={signUp}>
                {isLoading && (
                  <svg
                    className="mr-2 h-4 w-4 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                  </svg>
                )}
                Sign Up
              </Button>
            </div>
          </div>

          <p className="px-8 text-center text-sm text-muted-foreground">
            <Button
              variant="link"
              className="underline hover:text-primary"
              onClick={() => navigate("/login")}
            >
              Already have an account? Sign in
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
}
