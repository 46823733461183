import MerchantDevices from "../merchant-qr-code/MerchantDevices";
import TutorialDescriptionCard from "./TutorialDescriptionCard";

export default function AddDevicesTutorial() {
  return (
    <>
      <TutorialDescriptionCard
        title="Add Qbon Devices"
        description="Purchased a Qbon Device? Tap the add device button to scan the QR code now and activate it for your merchant account."
        icon={
          <>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* QR Code Frame */}
              <rect x="4" y="4" width="7" height="7" rx="1" fill="#FB3310" />
              <rect x="13" y="4" width="7" height="7" rx="1" fill="#7C3AED" />
              <rect x="4" y="13" width="7" height="7" rx="1" fill="#4C1D95" />

              {/* Center Square */}
              <rect x="13" y="13" width="7" height="7" rx="1" fill="#34D399" />

              {/* Inner Details */}
              <rect x="6" y="6" width="3" height="3" fill="white" />
              <rect x="15" y="6" width="3" height="3" fill="white" />
              <rect x="6" y="15" width="3" height="3" fill="white" />
              <rect x="15" y="15" width="3" height="3" fill="white" />
            </svg>
          </>
        }
      />
      <div className={"pt-6"}>
        <MerchantDevices />
      </div>
    </>
  );
}
