import { UploadIcon } from "lucide-react";
import { Button } from "../components/ui/button";
import { Card } from "../components/ui/card";
import ImageCropModal from "./ImageCropper";
import { useState } from "react";

export interface ImagePickerProps {
  onComplete: (imageUrl: string, file: File) => void;
  onRemove: () => void;
  imageUrl?: string;
}

export default function ImagePicker({
  onComplete,
  onRemove,
  imageUrl,
}: ImagePickerProps) {
  const [cropModalOpen, setCropModalOpen] = useState<boolean>(false);
  const [cropSrc, setCropSrc] = useState<string>("");

  const handleImageCropComplete = async (
    croppedImage: Blob | null
  ): Promise<void> => {
    if (croppedImage) {
      const croppedFile = new File(
        [croppedImage],
        `cropped_coupon_image.${croppedImage.type}`,
        { type: croppedImage.type }
      );
      if (croppedFile) {
        onComplete(URL.createObjectURL(croppedFile), croppedFile);
      }
    }
    setCropModalOpen(false);
  };

  return (
    <>
      <Card className="p-6 border-dashed border-2 border-gray-300">
        <div className="flex flex-col items-center justify-center gap-4">
          {imageUrl ? (
            <>
              <img
                src={imageUrl}
                alt="Coupon preview"
                className="max-w-full h-auto rounded-lg shadow-sm"
              />
              <Button onClick={onRemove}>Remove Image</Button>
            </>
          ) : (
            <label className="cursor-pointer flex flex-col items-center gap-2">
              <UploadIcon className="h-12 w-12 text-gray-400" />
              <span className="text-sm text-gray-600">
                Click to upload image
              </span>
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={(event: any) => {
                  if (event.target.files?.[0]) {
                    setCropSrc(URL.createObjectURL(event.target.files[0]));
                    setCropModalOpen(true);
                  }
                }}
              />
            </label>
          )}
        </div>
      </Card>
      {cropModalOpen && (
        <ImageCropModal
          src={cropSrc}
          aspect={[16, 9]}
          onComplete={(croppedImage: Blob | null) =>
            handleImageCropComplete(croppedImage)
          }
          onClose={() => setCropModalOpen(false)}
        />
      )}
    </>
  );
}
