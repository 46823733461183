import classNames from "classnames";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  onButtonClick?: () => void;
  explicitCloseButton?: boolean;
  children: React.ReactNode;
  title?: string;
  buttonLabel?: string;
  buttonDisabled?: boolean;
  buttonVariant?: "primary" | "secondary" | "warning" | "danger" | "outline";
  buttonLoading?: boolean;
  maxWidth?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl";
}

export function Modal({
  open,
  onClose,
  children,
  title,
  buttonLabel,
  onButtonClick,
  explicitCloseButton,
  buttonVariant,
  buttonLoading,
  buttonDisabled,
  maxWidth = "md",
}: ModalProps) {
  return (
    <AlertDialog open={open} onOpenChange={onClose}>
      <AlertDialogContent className={classNames(
        "transform overflow-show rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all w-full",
        maxWidth ? `max-w-${maxWidth}` : `max-w-md`
      )}>
        {title && (
          <AlertDialogHeader>
            <AlertDialogTitle>{title}</AlertDialogTitle>
          </AlertDialogHeader>
        )}
        
        <AlertDialogDescription className="mt-2 w-full">
          {children}
        </AlertDialogDescription>

        <AlertDialogFooter className="mt-4 px-4 sm:px-6 flex flex-row-reverse">
          {explicitCloseButton && (
            <AlertDialogCancel
              disabled={buttonDisabled}
              className={buttonLoading ? "opacity-50 cursor-not-allowed" : ""}
            >
              Close
            </AlertDialogCancel>
          )}
          <AlertDialogAction
            onClick={onButtonClick}
            disabled={buttonDisabled}
            className={classNames(
              buttonLoading ? "opacity-50 cursor-not-allowed" : "",
              buttonVariant === "secondary" ? "bg-gray-500 hover:bg-gray-600" :
              buttonVariant === "warning" ? "bg-yellow-500 hover:bg-yellow-600" :
              buttonVariant === "danger" ? "bg-red-500 hover:bg-red-600" :
              buttonVariant === "outline" ? "bg-white border-2 border-gray-300 text-gray-700 hover:bg-gray-50" :
              "bg-blue-500 hover:bg-blue-600"
            )}
          >
            {buttonLabel || "Done"}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
