import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Session } from '@supabase/supabase-js';
import { fetchJson } from '../util/fetcher';
import { User } from '../models/users';
import { Merchant } from '../models/merchant';
import supabaseClient from './supabase';

const Routes = {
  queryUser: (externalId: string) => `/users?externalId=${externalId}`,
  user: (userId: number) => `/users/${userId}`,
};

export function useProviderAuth() {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>();
  const [merchant, setMerchant] = useState<Merchant>();
  const [session, setSession] = useState<Session | null>(null);
  const [isSessionLoading, setIsSessionLoading] = useState<boolean>(true);

  const setMerchantLocal = useCallback(
    (merchant: Merchant) => {
      localStorage.setItem('qbonMerchant', JSON.stringify(merchant));
      setMerchant(merchant);
      navigate('dashboard/profile');
    },
    [navigate],
  );

  const updateMerchant = useCallback((merchant: Merchant) => {
    localStorage.setItem('qbonMerchant', JSON.stringify(merchant));
    setMerchant(merchant);
  }, []);

  const exitMerchant = useCallback(() => {
    localStorage.removeItem('qbonMerchant');
    setMerchant(undefined);
    navigate('/home/merchants');
  }, [navigate]);

  const logOut = useCallback(() => {
    localStorage.clear();
    setMerchant(undefined);
    supabaseClient.auth.signOut();
  }, []);

  const refreshUserSession = useCallback((newSession?: Session) => {
    const sessionToUse = newSession || session;
    if (!sessionToUse) return;

    const fetchUser = async () => {
      setIsSessionLoading(true);
      try {
        const user = await fetchJson<User>(Routes.queryUser(sessionToUse.user.id), sessionToUse.access_token);
        if (user.email && user.email !== '') {
          setUser(user);
        } else {
          throw new Error('No user found');
        }
      } catch (error) {
        setSession(null);
        supabaseClient.auth.signOut();
        console.error(error);
      } finally {
        setIsSessionLoading(false);
      }
    };
    fetchUser();
  }, [session]);

  const getMerchantAndUser = () => {
    if (!merchant) {
      navigate('/home');
      throw new Error('No merchant found');
    }

    if (!user) {
      navigate('/login');
      throw new Error('No user found');
    }

    return {
      merchant,
      user,
    };
  };

  const getUser = () => {
    if (!user) {
      navigate('/login');
      throw new Error('No user found');
    }

    return {
      user,
    };
  };

  useEffect(() => {
    const { data } = supabaseClient.auth.onAuthStateChange(async (event, newSession) => {
      console.log('event', event);
      
      if (event === 'INITIAL_SESSION') {
        setIsSessionLoading(false);
        return;
      }

      if (event === 'SIGNED_IN' && !session) {
        if (!newSession?.user?.id || !newSession?.access_token) return;

        try {
          const user = await fetchJson<User>(Routes.queryUser(newSession.user.id), newSession.access_token);
          if (!user) {
            supabaseClient.auth.signOut();
            return;
          }
          const currentMerchant = localStorage.getItem('qbonMerchant');
          setMerchant(currentMerchant ? (JSON.parse(currentMerchant) as Merchant) : undefined);
          setUser(user);
          setSession(newSession);
        } catch {
          supabaseClient.auth.signOut();
        } finally {
          setIsSessionLoading(false);
        }
        return;
      }

      if (event === 'SIGNED_OUT') {
        setSession(null);
        setUser(undefined);
        setMerchant(undefined);
        setIsSessionLoading(false);
        localStorage.removeItem('qbonMerchant');
        navigate('/login');
        return;
      }

      if (event === 'TOKEN_REFRESHED' && session) {
        setSession(session);
        refreshUserSession(session);
        return;
      }

      if (event === 'USER_UPDATED') {
        setIsSessionLoading(false);
      }
    });

    return () => {
      data.subscription.unsubscribe();
    };
  }, [navigate, session, refreshUserSession]);

  return {
    user,
    session,
    setMerchant: setMerchantLocal,
    refreshUserSession,
    exitMerchant,
    updateMerchant,
    merchant,
    isSessionLoading,
    logOut,
    getMerchantAndUser,
    getUser,
  };
}
