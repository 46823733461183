import { SystemMetricView } from './SystemMetricView';
import { MerchantMetricView } from './MerchantMetricView';
import { useEffect, useState } from 'react';
import { getDifferenceInDays, MetricType } from './type';
import DateRangePicker from '../library/DateRangePicker';
import Combobox from '../library/Combobox';
import { ErrorMessage } from '../library/ErrorMessage';

interface Props {
  isSystemMetrics: boolean;
  defaultMetricType: MetricType;
  primaryColor: string;
  couponId?: number;
  raffleId?: number;
}

const adminOptions: { title: string, value: MetricType }[] = [
  { value: 'MERCHANT_CREATED', title: 'Merchants Created Count' },
  { value: 'USER_CREATED', title: 'Users Created Count' },
];

const merchantOptions: { title: string, value: MetricType }[] = [
  { value: 'STORE_FRONT_ARRIVED', title: 'Tap and Scan Count' },
  { value: 'LINK_CLICKED', title: 'Links Clicked' },
  { value: 'SOCIAL_SHARE', title: 'Social Platforms Shared To' },
  { value: 'COUPON_CLAIMED', title: 'Qbons Claimed Count' },
  { value: 'COUPON_REDEEMED', title: 'Qbons Redeemed Count' },
  { value: 'COUPON_CREATED', title: 'Qbons Created Count' },
  { value: 'CONTENT_POSTED', title: 'Content Posted By User Count' },
  { value: 'COUPON_VIEWED', title: 'Qbon Views' },
  { value: 'RAFFLE_ENTERED', title: 'Raffle Entries' },
];

export function MetricView({ primaryColor, isSystemMetrics, defaultMetricType, couponId, raffleId }: Props) {
  const [metric, setMetric] = useState<{
    value: MetricType,
    title: string;
  }>();
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [error, setError] = useState<string[]>([]);

  useEffect(() => {
    setToDate(new Date());
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    setFromDate(thirtyDaysAgo);

    const initialMetric = isSystemMetrics ? adminOptions.find((m) => m.value === defaultMetricType) || adminOptions[0] :
      merchantOptions.find((m) => m.value === defaultMetricType) || merchantOptions[0];
    setMetric(initialMetric);
  }, [defaultMetricType, isSystemMetrics]);

  return (
    <>
      {metric && (
        <div className="space-y-4 p-4 bg-white rounded-lg shadow-sm">
          {error.length > 0 && (
            <div className="bg-red-50 p-3 rounded-md">
              <ErrorMessage message={error} />
            </div>
          )}

          <div className="flex gap-4">
            <div className={!raffleId && !couponId ? "w-1/2" : "hidden"}>
              <Combobox
                value={metric}
                options={isSystemMetrics ? adminOptions : merchantOptions.filter((o) => o.value !== 'COUPON_VIEWED' && o.value !== 'RAFFLE_ENTERED')}
                onChange={(val) => setMetric(val)}
                displayKey={'title'}
                label={"Metric"}
              />
            </div>
            <div className={!raffleId && !couponId ? "w-1/2" : "w-full"}>
              <DateRangePicker
                label={"Period"}
                date={{ from: fromDate, to: toDate }}
                onDateChange={(dates) => {
                  const start = dates?.from;
                  const end = dates?.to;
                  if (start && end) {
                    const val = getDifferenceInDays(start, end);
                    if (val > 180) {
                      setError(['Dates must be within 180 days of each other']);
                    } else {
                      setError([]);
                      setFromDate(start);
                      setToDate(end);
                    }
                  }
                }}
              />
            </div>
          </div>

          <div className="pt-4">
            {isSystemMetrics && fromDate && toDate ? (
              <SystemMetricView
                metricType={metric.value}
                fromDate={fromDate}
                toDate={toDate}
                primaryColor={primaryColor}
              />
            ) : fromDate && toDate ? (
              <MerchantMetricView
                metricType={metric.value}
                fromDate={fromDate}
                toDate={toDate}
                raffleId={raffleId}
                couponId={couponId}
                primaryColor={primaryColor}
              />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}