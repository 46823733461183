import { Merchant } from "../../../models/merchant";
import ImageCircleUpload from "../../../common/library/ImageCircleUpload/ImageCircleUpload";
import CoverImageUpload from "../../../common/library/CoverImageUpload.tsx/CoverImageUpload";
import { useMerchantFiles } from "../../../hooks/merchant-files.hook";
import useMerchants from "../../../hooks/merchants.hook";
import { User } from "../../../models/users";
import { useState } from "react";
import { motion } from "framer-motion";
import TutorialDescriptionCard from "./TutorialDescriptionCard";

interface Props {
  user: User;
  merchant: Merchant;
}

export function LogoAndCoverTutorial({ user, merchant }: Props) {
  const [editingMerchant, setEditingMerchant] = useState<Merchant>(merchant);

  // Merchant data
  const { useUpdateMerchantMutation } = useMerchants();
  const updateMerchant = useUpdateMerchantMutation(
    merchant.id,
    (res) => {
      setEditingMerchant({
        ...editingMerchant,
        logoImageUrl: res.logoImageUrl,
        coverImageUrl: res.coverImageUrl,
      });
    },
    () => {}
  );

  const { useUploadMerchantFileMutation } = useMerchantFiles();
  const uploadLogo = useUploadMerchantFileMutation(
    user.id,
    merchant.id,
    (res) => {
      merchant &&
        updateMerchant.mutate({
          ...editingMerchant,
          logoImageUrl: res.url,
        });
    },
    (error) => {}
  );
  const uploadCover = useUploadMerchantFileMutation(
    user.id,
    merchant.id,
    (res) => {
      merchant &&
        updateMerchant.mutate({
          ...editingMerchant,
          coverImageUrl: res.url,
        });
    },
    (error) => {}
  );

  const saveImage = async (blob: Blob, type: "cover" | "logo") => {
    if (merchant && user) {
      const file = new File([blob], "image.jpg", { type: blob.type });
      type === "logo" ? uploadLogo.mutate(file) : uploadCover.mutate(file);
    }
  };

  return (
    <div className="space-y-8 font-nunito">
      <TutorialDescriptionCard
        title="Brand Your Profile"
        description="Make your brand shine! Upload a distinctive logo and eye-catching cover photo that reflects your unique identity"
        icon={
          <>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="3" y="4" width="18" height="16" rx="2" fill="#fb3310" />
              <path
                d="M3 16L8 11C8.928 10.107 10.072 10.107 11 11L16 16"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M14 14L16 12C16.928 11.107 18.072 11.107 19 12L21 14"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <circle cx="9" cy="8" r="2" fill="#34D399" />
            </svg>
          </>
        }
      />

      <div className="flex flex-col items-center gap-8">
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="relative"
        >
          <ImageCircleUpload
            saving={uploadLogo.status === "pending"}
            imageUrl={
              editingMerchant.logoImageUrl ||
              "https://prod-werehere-images.s3.amazonaws.com/QBON.png"
            }
            onSave={(file) => saveImage(file, "logo")}
          />
          <div className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 text-sm text-gray-500 whitespace-nowrap">
            Click to change logo
          </div>
        </motion.div>

        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="relative w-full max-w-[400px]"
        >
          <CoverImageUpload
            saving={uploadCover.status === "pending"}
            imageUrl={
              editingMerchant.coverImageUrl ||
              "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
            onSave={(file) => saveImage(file, "cover")}
          />
          <div className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 text-sm text-gray-500 whitespace-nowrap">
            Click to change cover photo
          </div>
        </motion.div>
      </div>
    </div>
  );
}
