import React, { useState, useCallback } from "react";
import "react-image-crop/dist/ReactCrop.css";
import Cropper, { Area, Point } from "react-easy-crop";

interface ImageCropModalProps {
  src: string;
  aspect: [number, number];
  onComplete: (croppedImage: Blob | null) => void;
  onClose: () => void;
}

const ImageCropModal: React.FC<ImageCropModalProps> = ({
  src,
  aspect,
  onComplete,
  onClose,
}) => {
  const [crop, setCrop] = useState<Area>({ x: 0, y: 0, width: 0, height: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCropChange = (point: Point) => {
    const newCrop: Area = { ...crop, x: point.x, y: point.y };
    setCrop(newCrop);
  };

  const handleCrop = async () => {
    try {
      if (croppedAreaPixels.width && croppedAreaPixels.height) {
        const croppedImage = await getCroppedImg(src, croppedAreaPixels);
        onComplete(croppedImage);
      } else {
        alert("Please select an area to crop.");
      }
    } catch (error) {
      console.error("Error cropping image:", error);
      onComplete(null);
    }
  };

  const getCroppedImg = async (
    imageSrc: string,
    croppedAreaPixels: Area
  ): Promise<Blob | null> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          resolve(null);
          return;
        }
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            resolve(null);
          }
        }, "image/jpeg");
      };
      image.src = imageSrc;
    });
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-2xl w-[90%] h-[80%] max-w-4xl p-6 flex flex-col">
        <div className="relative flex-1 mb-6 rounded-lg overflow-hidden bg-gray-100">
          <Cropper
            image={src}
            crop={crop}
            aspect={aspect[0] / aspect[1]}
            onCropChange={handleCropChange}
            onCropComplete={onCropComplete}
            zoomWithScroll
            showGrid
            cropShape="round"
            objectFit="contain"
            minZoom={0.5}
            style={{
              containerStyle: {
                width: "100%",
                height: "100%",
                backgroundColor: "#f3f4f6",
              },
              cropAreaStyle: {
                border: "2px solid white",
                boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.5)",
              },
              mediaStyle: {
                transition: "all 0.15s ease",
              },
            }}
          />
        </div>
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-6 py-2.5 rounded-lg border border-gray-200 text-gray-700 hover:bg-gray-50 transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleCrop}
            className="px-6 py-2.5 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200"
          >
            Crop Image
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropModal;
