import { usePayments } from "../../../hooks/payment.hook";
import { CreateCheckoutSession, Product } from "../../../models/payment";
import { useEffect } from "react";
import Button from "../../../common/library/Button";
import Spinner from "../../../common/library/Spinner";
import { Merchant } from "../../../models/merchant";
import { User } from "../../../models/users";
import TutorialDescriptionCard from "./TutorialDescriptionCard";
import { PricingBox } from "../../../common/library/PricingBox";
import { motion } from "framer-motion";

interface Props {
  merchant: Merchant;
  user: User;
  onComplete: (acceptedSubscription: boolean) => void;
  onGoBack: () => void;
}

export default function SubscribeTutorial({
  merchant,
  user,
  onComplete,
  onGoBack,
}: Props) {
  const { useSubscriptionQuery, useProductsQuery, useCreateCheckoutMutation } =
    usePayments();

  const {
    data: subscription,
    isLoading: isLoadingSubscription,
    isSuccess: isSuccessSubscription,
  } = useSubscriptionQuery(user.id, merchant.id);
  const { data: products } = useProductsQuery(
    merchant.licenseId,
    merchant.address.state
  );
  const createCheckout = useCreateCheckoutMutation(
    user.id,
    merchant.id,
    (res) => {
      onComplete(true);
      window.location.replace(res.url);
    },
    (error) => {
      console.error(error);
    }
  );

  const checkout = (product: Product) => {
    const createCheckoutRequest: CreateCheckoutSession = {
      successUrl: `${window.location}`,
      cancelUrl: `${window.location}`,
      merchantId: merchant.id,
      lineItems: [
        {
          productId: product.id,
          price: product.priceId,
          quantity: 1,
        },
      ],
      mode: "subscription",
      email: user.email,
    };
    createCheckout.mutate(createCheckoutRequest);
  };

  useEffect(() => {}, []);

  return (
    <div className="h-full flex flex-col">
      <TutorialDescriptionCard
        title="Subscribe to Qbon"
        description="Subscribe to Qbon to unlock all of our features!"
        icon={
          <>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* Crown */}
              <path
                d="M3 17L6.5 7L12 11L17.5 7L21 17H3Z"
                fill="#FB3310"
                stroke="#4C1D95"
                strokeWidth="2"
                strokeLinejoin="round"
              />

              {/* Crown Points */}
              <circle cx="6.5" cy="7" r="2" fill="#7C3AED" />
              <circle cx="12" cy="11" r="2" fill="#7C3AED" />
              <circle cx="17.5" cy="7" r="2" fill="#7C3AED" />

              {/* Base */}
              <rect x="4" y="17" width="16" height="3" rx="1" fill="#34D399" />
            </svg>
          </>
        }
      />

      {products &&
        (!isSuccessSubscription || subscription?.status !== "active") && (
          <PricingBox
            loading={createCheckout.status === "pending"}
            title={products[0].name}
            price={(products[0].defaultPrice / 100).toString()}
            rate={"Month"}
            description={products[0].description}
            features={[
              "Qbon Mobile App Listing",
              "Qbons",
              "Giveaways",
              "Access to User Generated Content",
              "Customer Location Verification",
              "Content Feed",
              "Qbon Scanner",
              "Metrics",
            ]}
            onPurchase={() => {
              checkout(products[0]);
            }}
          />
        )}

      {isSuccessSubscription && subscription.status === "active" && (
        <div className="flex gap-4 pt-12">
          <div className="flex-1 flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="text-center flex items-center gap-3"
            >
              <div>
                <div className="text-2xl font-bold text-primary">
                  You are subscribed!
                </div>
                <div className="text-gray-600 mt-2">
                  Thank you for subscribing to Qbon
                </div>
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  fill="#34D399"
                />
                <path
                  d="M8 13L10.5 15.5L16 10"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </motion.div>
          </div>
        </div>
      )}

      <div className="mt-auto space-y-4">
        {!isLoadingSubscription ? (
          <div className="space-y-4 pb-4">
            {isSuccessSubscription && subscription.status === "active" && (
              <div className="flex gap-4">
                <Button
                  label={"Go Back"}
                  variant={"outline"}
                  onClick={onGoBack}
                  buttonWidth={"full"}
                />
                <Button
                  label={"Continue"}
                  onClick={() => onComplete(true)}
                  buttonWidth={"full"}
                />
              </div>
            )}

            {merchant.status !== "SUBSCRIBED" && (
              <div className="flex gap-4">
                <Button
                  buttonWidth={"full"}
                  label={"Go Back"}
                  onClick={onGoBack}
                  variant={"outline"}
                />
                <Button
                  buttonWidth={"full"}
                  label={"No, Thank You!"}
                  onClick={() => onComplete(false)}
                  variant={"outline"}
                />
              </div>
            )}
          </div>
        ) : (
          <Spinner height={10} width={10} color="red" />
        )}
      </div>
    </div>
  );
}
