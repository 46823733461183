import * as React from "react"
import {
  Table as ShadcnTable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table"
import { BanIcon, CheckIcon } from "@heroicons/react/outline"
import { cn } from "../../lib/utils"
import Pagination, { PageInfo } from "./Pagination"
import Spinner from "./Spinner"

export interface TableColumn<T> {
  key?: keyof T
  label: string
  check?: (value: T) => boolean
  format?: (value: T) => React.ReactNode
  className?: string
}

export interface TableProps<T> {
  columns: TableColumn<T>[]
  data: T[]
  loading?: boolean
  pagination?: {
    pageInfo: PageInfo
    onChange: (pageInfo: PageInfo) => void
  }
  onRowClick?: (row: T) => void
}

export default function Table<T>(props: TableProps<T>) {
  const { columns, data, pagination, onRowClick, loading } = props

  return (
    <div className="py-4 w-full overflow-auto">
      <ShadcnTable>
        <TableHeader>
          <TableRow>
            {columns.map((column: TableColumn<T>, index: number) => (
              <TableHead
                key={index}
                className={cn(
                  "border-red-700 bg-white text-center text-xs font-semibold text-gray-600 uppercase tracking-wider",
                  column.className
                )}
              >
                <span className="float-left">{column.label}</span>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {!loading ? (
            data.length > 0 ? (
              data.map((row: T, index: number) => (
                <TableRow
                  key={index}
                  className={cn(
                    "hover:bg-red-100 bg-white",
                    onRowClick && "cursor-pointer"
                  )}
                  onClick={() => onRowClick?.(row)}
                >
                  {columns.map((column: TableColumn<T>, columnIndex: number) => (
                    <TableCell
                      key={columnIndex}
                      className={cn(
                        "text-sm text-gray-900",
                        column.className
                      )}
                    >
                      <div className="float-left flex items-center gap-3">
                        {column.check?.call(null, row) && (
                          <CheckIcon className="h-4 w-4" />
                        )}
                        {column.key && !column.format && (
                          <span>{row[column.key] as any}</span>
                        )}
                        {column.format && !column.key && column.format(row)}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <div className="flex justify-center items-center gap-2 py-6">
                    <BanIcon className="h-8 w-8 text-[#fb3310]" />
                    <div className="font-bold">No records available</div>
                  </div>
                </TableCell>
              </TableRow>
            )
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <div className="flex justify-center py-6">
                  <Spinner height={8} width={8} color="red" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {pagination && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Pagination
                  pageInfo={pagination.pageInfo}
                  onChange={pagination.onChange}
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </ShadcnTable>
    </div>
  )
}
