import { motion } from "framer-motion";

interface Props {
  title: string;
  description: string;
  icon: React.ReactNode;
}

export default function TutorialDescriptionCard({
  title,
  description,
  icon,
}: Props) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-gradient-to-br from-white to-gray-50 rounded-xl shadow-lg p-4 flex items-center justify-between gap-6 border border-gray-100"
    >
      <div className="flex-1 space-y-2">
        <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
        <p className="text-gray-600 leading-relaxed">{description}</p>
      </div>
      <div className="rounded-full bg-primary/10 p-3">{icon}</div>
    </motion.div>
  );
}
