import useAuthContext from "../../../hooks/auth-context.hook";
import CreateRaffleInfo from "./CreateRaffleInfo";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router";
import { useRaffles } from "../../../hooks/raffle.hook";
import { useRef, useState } from "react";
import { ErrorMessage } from "../../../common/library/ErrorMessage";
import Button from "../../../common/library/Button";
import CreateQbonForm from "./CreateQbonForm";
import { useMerchantFiles } from "../../../hooks/merchant-files.hook";

export default function CreateRafflePage() {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const errorDivRef = useRef<HTMLDivElement>(null);

  const { merchant, user } = auth.getMerchantAndUser();
  const { useCreateRaffleMutation } = useRaffles();
  const createRaffleMutation = useCreateRaffleMutation(
    merchant.id,
    () => {
      navigate("/dashboard/giveaways");
    },
    (error) => {
      setErrors(error.message);
    }
  );

  const { useUploadMerchantFileMutation } = useMerchantFiles();
  const uploadFileMutation = useUploadMerchantFileMutation(
    user.id,
    merchant.id,
    (res) => {
      executeCreateRaffle(res.url);
    },
    () => {}
  );

  const [file, setFile] = useState<File | undefined>(undefined);
  const [errors, setErrors] = useState<string[]>([]);
  const [raffleData, setRaffleData] = useState<any>({});
  const [couponData, setCouponData] = useState<any>({
    name: "",
    description: "",
    code: null,
    startDate: null,
    endDate: null,
    discountType: null,
    discountAmount: null,
    redemptionMethodId: null,
    couponImageUrl: null,
  });

  const executeCreateRaffle = (imageUrl?: string) => {
    createRaffleMutation.mutate({
      name: raffleData.name,
      description: raffleData.description,
      startDate: raffleData.startDate,
      endDate: raffleData.endDate,
      coupon: {
        name: couponData.name,
        description: couponData.description,
        type: "SHARE",
        code: couponData.code,
        startDate: couponData.startDate,
        endDate: couponData.endDate,
        status: "AVAILABLE",
        discountType: couponData.discountType,
        discountAmount: couponData.discountAmount,
        couponImageUrl: imageUrl,
        redemptionMethodId: couponData.redemptionMethodId,
      },
    });
  };

  const validateAndCreateRaffle = () => {
    setErrors([]);

    if (!raffleData || !couponData) {
      setErrors(["Raffle and Qbon data must be filled out!"]);
      scrollToTop();
      return;
    }

    if (raffleData.name === "") {
      setErrors(["Raffle name must be filled out!"]);
      scrollToTop();
      return;
    }

    if (raffleData.description === "") {
      setErrors(["Raffle description must be filled out!"]);
      scrollToTop();
      return;
    }

    if (couponData.name === "") {
      setErrors(["Coupon name must be filled out!"]);
      scrollToTop();
      return;
    }

    if (couponData.description === "") {
      setErrors(["Coupon description must be filled out!"]);
      scrollToTop();
      return;
    }

    if (file) {
      uploadFileMutation.mutate(file);
    } else {
      executeCreateRaffle();
    }
  };

  const scrollToTop = () => {
    errorDivRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2 px-2 md:px-0">
        <h1
          className="font-bold text-xl text-[#fb3310] cursor-pointer hover:underline"
          onClick={() => navigate("/dashboard/giveaways")}
        >
          Giveaways
        </h1>
        <ChevronRightIcon
          className="h-5 w-5 text-gray-500"
          aria-hidden="true"
        />
        <h1 className="font-bold text-xl">Create Giveaway</h1>
      </div>

      <div ref={errorDivRef}>
        <ErrorMessage message={errors} />
      </div>

      <h2 className="font-bold text-lg mb-4">Giveaway Info</h2>
      <CreateRaffleInfo onChange={setRaffleData} />

      <h2 className="font-bold text-lg mb-4">Qbon Details</h2>
      <CreateQbonForm
        merchant={merchant}
        couponData={couponData}
        onChange={(data, file) => {
          setCouponData(data);
          setFile(file);
        }}
      />

      <div className="px-2 md:px-0">
        <Button
          label="Create Raffle"
          buttonWidth="full"
          onClick={validateAndCreateRaffle}
          loading={createRaffleMutation.status === "pending"}
        />
      </div>
    </div>
  );
}
