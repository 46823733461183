import BasicForm, { FormField } from "../../../common/library/BasicForm";
import { useCallback, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { Merchant } from "../../../models/merchant";
import useCoupons from "../../../hooks/coupon.hook";
import DateRangePicker from "../../../common/library/DateRangePicker";
import ImagePicker from "../../../common/library/ImagePicker";
import { DiscountType } from "../../../models/coupon";

export interface CouponData {
  name: string;
  description: string;
  code: string | null;
  startDate: Date | null;
  endDate: Date | null;
  discountType: DiscountType | null;
  discountAmount: number | null;
  redemptionMethodId: number | null;
  couponImageUrl: string | null;
}

interface Props {
  merchant: Merchant;
  couponData: any;
  onChange: (couponData: any, imageFile?: File) => void;
}

export default function CreateQbonForm({
  merchant,
  couponData,
  onChange,
}: Props) {
  const { useCouponRedemptionMethodsQuery } = useCoupons();
  const { data: redemptionMethods } = useCouponRedemptionMethodsQuery(
    merchant.id
  );  
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const fields: FormField[] = [
    {
      label: "Name",
      required: true,
      value: couponData.name,
      onValueChange: (name) => {
        const newData = { ...couponData, name };
        onChange(newData);
      },
    },
    {
      label: "Description",
      required: true,
      value: couponData.description,
      onValueChange: (description) => {
        const newData = { ...couponData, description };
        onChange(newData);
      },
    },
    {
      label: "Redemption Method",
      value: (
        redemptionMethods.find(
          (r) => r.id === couponData.redemptionMethodId
        ) ?? { name: "Self redeem or scan" }
      ).name,
      combobox: true,
      options: redemptionMethods.map((method) => method.name),
      onValueChange: (value) => {
        if (value) {
          const id = (
            redemptionMethods.find((r) => r.name === value) ?? { id: 1 }
          ).id;
          const newData = { ...couponData, redemptionMethodId: id };
          onChange(newData);
        }
      },
    },
    {
      label: "Discount Type",
      value:
        couponData.discountType === null
          ? "Disabled"
          : couponData.discountType === "AMOUNT"
          ? "Amount ($)"
          : "Percentage (%)",
      combobox: true,
      options: ["Disabled", "Amount ($)", "Percentage (%)"],
      onValueChange: (value) => {
        if (value === "Disabled") {
          const newData = { ...couponData, discountType: null };
          onChange(newData);
        }
        if (value === "Amount ($)") {
          const newData = { ...couponData, discountType: "AMOUNT" };
          onChange(newData);
        }
        if (value === "Percentage (%)") {
          const newData = { ...couponData, discountType: "PERCENTAGE" };
          onChange(newData);
        }
      },
    },
    ...(couponData.discountType !== null
      ? [
          {
            label: "Discount Amount",
            value:
              couponData.discountAmount === null
                ? couponData.discountType === "PERCENTAGE"
                  ? `%1`
                  : 1
                : `${
                    couponData.discountType === "PERCENTAGE" ? "%" : "$"
                  }${parseFloat(couponData.discountAmount).toFixed(2)}`,
            onValueChange: (value: string) => {
              let input = value
                .replace("%", "")
                .replace("$", "")
                .replace(/[^0-9]/g, "");
              if (input.length > 10) {
                input = input.slice(0, 4);
              }
              while (input.length < 10) {
                input = "0" + input;
              }
              input =
                input.slice(0, input.length - 2) +
                "." +
                input.slice(input.length - 2);
              const val = parseFloat(input);

              onChange({
                ...couponData,
                discountAmount:
                  val > 100 && couponData.discountType === "PERCENTAGE"
                    ? 100
                    : val < 0 && couponData.discountType === "PERCENTAGE"
                    ? 1
                    : val > 1000000 && couponData.discountType === "AMOUNT"
                    ? 1000000
                    : val,
              });
            },
          } as FormField,
        ]
      : []),
    {
      label: "Discount Code",
      value: couponData.code,
      onValueChange: (code) => {
        const newData = { ...couponData, code };
        onChange(newData);
      },
    },
  ];

  const message = useCallback(() => {
    if (
      couponData.startDate === undefined ||
      couponData.startDate === null ||
      couponData.startDate < new Date()
    ) {
      return (
        <div className="bg-yellow-50 p-6 rounded-lg m-auto">
          <div className="text-yellow-700 flex md:w-[50%]">
            <div className="w-[50px] pr-4 m-auto">
              <ExclamationCircleIcon height={32} width={32} />
            </div>
            <div className="m-auto">
              Coupons with no start date, start dates of today, or start dates
              before today will be active immediately
            </div>
          </div>
        </div>
      );
    }
  }, [couponData]);

  return (
    <>
      {message()}
      <div className="py-3">
        <BasicForm fields={fields} />

        <div className="py-4">
          <h3 className="text-sm font-medium mb-2">Qbon Validity Period</h3>
          <DateRangePicker
            date={{ from: dateRange.startDate, to: dateRange.endDate }}
            onDateChange={(date) =>
              setDateRange({
                startDate: date?.from ?? new Date(),
                endDate: date?.to ?? new Date(),
              })
            }
          />
        </div>

        <div className="py-3">
          <div className="font-bold mb-2">
            Coupon Image {"(JPEG or PNG - 16:9 Aspect Ratio Suggested)"}
          </div>
          <div className="text-sm text-gray-600 mb-4">
            Give your coupon an image{" "}
            <span className="text-red-400">
              (if not set default will be used)
            </span>
          </div>

          <ImagePicker
            onComplete={(imageUrl, file) => {
              onChange({ ...couponData, couponImageUrl: imageUrl }, file);
            }}
            imageUrl={couponData.couponImageUrl}
            onRemove={() => {
              onChange({ ...couponData, couponImageUrl: null }, undefined);
            }}
          />
        </div>
      </div>
    </>
  );
}
