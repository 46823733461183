import * as React from "react"
import { CalendarIcon, ClipboardIcon, PencilIcon, SaveIcon } from "lucide-react"
import { format } from "date-fns"
import { Button } from "../components/ui/button"
import { Input as ShadcnInput } from "../components/ui/input"
import { Label } from "../components/ui/label"
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover"
import { Tooltip, TooltipContent, TooltipTrigger } from "../components/ui/tooltip"
import { Calendar } from "../components/ui/calendar"

// Utility function to merge classnames
const cn = (...classes: string[]) => classes.filter(Boolean).join(' ')

export interface InputProps {
  value?: any
  onChange?: (value: string) => void
  placeholder?: string
  onEnter?: () => void
  label?: string
  disabled?: boolean
  inlineSaving?: boolean
  deletable?: boolean
  onSave?: () => void
  onDelete?: () => void
  type?: React.HTMLInputTypeAttribute
  helpTip?: string
  required?: boolean
  copy?: boolean
  icon?: React.ReactNode
}

export default function Input({
  value,
  onChange,
  placeholder,
  onEnter,
  label,
  disabled,
  inlineSaving = false,
  deletable,
  onSave,
  onDelete,
  type,
  helpTip,
  required,
  copy,
  icon,
}: InputProps) {
  const [disableWhileInline, setDisableWhileInline] = React.useState<boolean>(true)
  const [date, setDate] = React.useState<Date | undefined>(value ? new Date(value) : undefined)

  return (
    <div className="space-y-2">
      {label && (
        <div className="flex items-center gap-2">
          {icon && <span>{icon}</span>}
          <Label 
            className={cn(
              required ? "text-destructive" : "",
              disabled ? "text-muted-foreground" : ""
            )}
          >
            {required && "* "}
            {label}
          </Label>
          
          {helpTip && (
            <Tooltip>
              <TooltipTrigger>ⓘ</TooltipTrigger>
              <TooltipContent>{helpTip}</TooltipContent>
            </Tooltip>
          )}
        </div>
      )}

      <div className="flex gap-2">
        <div className="grow flex" onClick={() => setDisableWhileInline(false)}>
          {type === 'date' ? (
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !date ? "text-muted-foreground" : ""
                  )}
                  disabled={disabled}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date ? format(date, "PPP") : "Pick a date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={(newDate: Date | undefined) => {
                    setDate(newDate)
                    onChange?.(newDate?.toString() || '')
                  }}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          ) : (
            <div className="relative w-full">
              <ShadcnInput
                required={required}
                disabled={disabled || (inlineSaving && disableWhileInline)}
                type={type}
                className={cn(
                  copy ? "pr-10" : "",
                  inlineSaving && disableWhileInline ? "bg-muted" : ""
                )}
                placeholder={placeholder}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    onEnter?.()
                    setDisableWhileInline(true)
                  }
                }}
              />
              
              {copy && (
                <Button
                  size="icon"
                  variant="ghost"
                  className="absolute right-0 top-0 h-full px-3 hover:bg-transparent"
                  onClick={() => {
                    navigator.clipboard.writeText(value)
                    alert('Copied text')
                  }}
                >
                  <ClipboardIcon className="h-4 w-4" />
                </Button>
              )}
            </div>
          )}

          {inlineSaving && disableWhileInline && (
            <Button
              size="icon"
              variant="destructive"
              className="ml-2"
              onClick={() => setDisableWhileInline(false)}
            >
              <PencilIcon className="h-4 w-4" />
            </Button>
          )}
        </div>

        {inlineSaving && !disableWhileInline && (
          <Button
            size="icon"
            onClick={() => {
              setDisableWhileInline(true)
              onSave?.()
            }}
          >
            <SaveIcon className="h-4 w-4" />
          </Button>
        )}

        {deletable && (
          <Button
            variant="destructive"
            onClick={onDelete}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  )
}
