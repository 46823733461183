"use client"

import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { cn } from "../../lib/utils"
import { Button } from "../components/ui/button"
import { Calendar } from "../components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover"
import dayjs, { Dayjs } from "dayjs"

interface DatePickerProps {
  value?: Dayjs | null
  onChange?: (date: Dayjs | null) => void
}

const QbonDatePicker = React.forwardRef<HTMLDivElement, DatePickerProps>(
  ({ value, onChange }, ref) => {
    const [date, setDate] = React.useState<Date | undefined>(
      value ? value.toDate() : undefined
    )

    return (
      <div ref={ref}>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-full justify-start text-left font-normal",
                !date && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date ? format(date, "PPP") : <span>Pick a date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={date}
              onSelect={(newDate) => {
                setDate(newDate)
                if (onChange) {
                  // Convert Date to Dayjs if needed
                  onChange(newDate ? dayjs(newDate) : null)
                }
              }}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      </div>
    )
  }
)

QbonDatePicker.displayName = "QbonDatePicker"

export default QbonDatePicker