import './Coupon.css';

interface Props {
  merchantLogoUrl?: string;
  description: string;
  startDate?: Date | null;
  endDate?: Date | null;
  code?: string;
  couponImageUrl?: string;
  name?: string;
  discountType?: string;
  discountAmount?: number;
}

export default function CouponComp({
                                     description,
                                     startDate,
                                     endDate,
                                     code,
                                     couponImageUrl,
                                     name,
                                     discountType,
                                     discountAmount,
                                   }: Props) {
  const dateText = () => {
    if (startDate && endDate && startDate !== null && endDate !== null) {
      return `Valid ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
    }
    if (startDate && startDate !== null) {
      return `Valid from ${startDate.toLocaleDateString()}`;
    }
    if (endDate && endDate !== null) {
      return `Expires ${endDate.toLocaleDateString()}`;
    }
    return 'No expiration date';
  };

  return (
    <div className="max-w-md mx-auto bg-white rounded-2xl shadow-lg overflow-hidden transform transition hover:scale-[1.02] duration-300">
      {/* Coupon Image Section */}
      <div className="relative h-48">
        <img
          className="w-full h-full object-cover"
          src={couponImageUrl || 'https://cdn.pixabay.com/photo/2014/10/19/20/59/hamburger-494706_1280.jpg'}
          alt={name || 'Coupon'}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
      </div>

      {/* Dotted Separator */}
      <div className="relative">
        <div className="absolute left-0 right-0 h-4 flex justify-between items-center px-4">
          <div className="w-6 h-6 rounded-full bg-gray-100 -ml-8" />
          <div className="flex-1 border-t-2 border-dashed border-gray-300 mx-4" />
          <div className="w-6 h-6 rounded-full bg-gray-100 -mr-8" />
        </div>
      </div>

      {/* Content Section */}
      <div className="p-6 pt-8">
        {/* Discount Badge */}
        {discountType && discountAmount && (
          <div className="absolute top-40 right-6 bg-red-600 text-white px-4 py-2 rounded-full shadow-lg">
            <span className="font-bold text-lg">
              {discountType === 'AMOUNT' && '$'}{discountAmount}{discountType === 'PERCENTAGE' && '%'} OFF
            </span>
          </div>
        )}

        {/* Title */}
        <h2 className="text-2xl font-bold text-gray-800 text-center mb-3">
          {name}
        </h2>

        {/* Description */}
        <p className="text-gray-600 text-center mb-6">
          {description}
        </p>

        {/* Coupon Code */}
        {code ? (
          <div className="flex justify-center mb-4">
            <div className="bg-gray-100 px-6 py-3 rounded-lg border-2 border-dashed border-gray-300">
              <span className="font-mono font-bold text-lg">{code}</span>
            </div>
          </div>
        ) : (
          <div className="h-12 w-32 mx-auto border-2 border-dashed border-gray-300 rounded-lg mb-4" />
        )}

        {/* Date */}
        <p className="text-sm text-gray-500 italic text-center">
          {dateText()}
        </p>
      </div>
    </div>
  );
}
