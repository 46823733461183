import { useNavigate } from "react-router";
import { Button } from "../../common/components/ui/button";

export default function NewPasswordComplete() {
  const navigate = useNavigate();

  return (
    <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
        <div className="absolute inset-0">
          <img
            src={require("../../assets/cover_for_login.jpg")}
            alt="Login cover"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-black/50" /> {/* Dark overlay */}
        </div>
        <div className="relative z-20 flex items-center text-lg font-medium">
          <img
            src={require("../../assets/qbon_logo.png")}
            alt="qbon-logo"
            className="h-8 w-8 mr-2"
          />
          Qbon
        </div>
        <div className="relative z-20 mt-auto">
          <blockquote className="space-y-2">
            <p className="text-lg">
              "This platform has revolutionized how we manage our business."
            </p>
            <footer className="text-sm">Sofia Davis</footer>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              Password reset complete
            </h1>
            <p className="text-sm text-muted-foreground">
              Your password has been successfully reset
            </p>
          </div>

          <Button onClick={() => navigate("/login")}>Back to login</Button>
        </div>
      </div>
    </div>
  );
}
