import { useNavigate } from 'react-router';
import { useCallback, useState } from 'react';
import { Button } from '../../common/components/ui/button';
import { Input } from '../../common/components/ui/input';
import supabaseClient from '../../hooks/supabase';

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const signIn = useCallback(async () => {
    setIsLoading(true);
    setError(undefined);

    const result = await supabaseClient.auth.signInWithPassword({ 
      password, 
      email 
    });

    if (result.error) {
      setError(result.error.message);
    }

    setIsLoading(false);
  }, [password, email]);

  const signInWithProvider = useCallback(
    async (provider: 'google' | 'facebook') => {
      setError(undefined);
      const { error } = await supabaseClient.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: `${window.location}`,
        },
      });
      if (error) setError(error.message);
    },
    [],
  );

  return (
    <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
        <div className="absolute inset-0">
          <img 
            src={require('../../assets/cover_for_login.jpg')} 
            alt="Login cover"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-black/50" /> {/* Dark overlay */}
        </div>
        <div className="relative z-20 flex items-center text-lg font-medium">
          <img
            src={require('../../assets/qbon_logo.png')}
            alt="qbon-logo"
            className="h-8 w-8 mr-2"
          />
          Qbon
        </div>
        <div className="relative z-20 mt-auto">
          <blockquote className="space-y-2">
            <p className="text-lg">
              "This platform has revolutionized how we manage our business."
            </p>
            <footer className="text-sm">Sofia Davis</footer>
          </blockquote>
        </div>
      </div>
      <div className="px-4 lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col items-center space-y-6">
            <img
              src={require('../../assets/qbon_logo.png')}
              alt="qbon-logo"
              className="h-12 w-12"
            />
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Welcome back
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your credentials to sign in to your account
              </p>
            </div>
          </div>

          <div className="grid gap-6">
            <div className="grid gap-4">
              {error && (
                <div className="text-sm font-medium text-destructive">
                  {error}
                </div>
              )}
              <div className="grid gap-2">
                <Input
                  id="email"
                  placeholder="name@example.com"
                  type="email"
                  autoCapitalize="none"
                  autoComplete="email"
                  autoCorrect="off"
                  disabled={isLoading}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="px-3 py-2"
                />
                <Input
                  id="password"
                  placeholder="Password"
                  type="password"
                  autoComplete="current-password"
                  disabled={isLoading}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="px-3 py-2"
                />
              </div>
              <Button disabled={isLoading} onClick={signIn}>
                {isLoading && (
                  <svg
                    className="mr-2 h-4 w-4 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                  </svg>
                )}
                Sign In
              </Button>
            </div>
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-background px-2 text-muted-foreground">
                  Or continue with
                </span>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Button variant="outline" onClick={() => signInWithProvider('google')}>
                <img src={require('../../assets/google_logo.png')} className="mr-2 h-4 w-4" alt="google-logo" />
              </Button>
              <Button variant="outline" onClick={() => signInWithProvider('facebook')}>
                <img src={require('../../assets/facebook_logo.png')} className="mr-2 h-4 w-4" alt="facebook-logo" />
              </Button>
            </div>
          </div>

          <p className="px-8 text-center text-sm text-muted-foreground">
            <Button 
              variant="link" 
              className="underline hover:text-primary"
              onClick={() => navigate('/signup')}
            >
              Don't have an account? Sign up
            </Button>
            <Button
              variant="link"
              className="underline hover:text-primary"
              onClick={() => navigate('/forgot-password')}
            >
              Forgot password?
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
}
