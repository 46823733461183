import useContent from '../../../hooks/content.hook';
import useAuthContext from '../../../hooks/auth-context.hook';
import { useState } from 'react';
import Pagination from '../../../common/library/Pagination';
import Tabs from '../../../common/library/Tabs';
import Button from '../../../common/library/Button';
import { useSocialHandles } from '../../../hooks/use-socia-handles.hook';
import { Modal } from '../../../common/library/Modal';
import { GetContentPostDto } from '../../../models/content';
import { CheckIcon, DownloadIcon, XIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/outline';

interface Props {
  content: GetContentPostDto;
  showDelete: boolean;
  showUnapprove: boolean;
  onUnapprove: () => void;
  onDelete: () => void;
  onApprove: () => void;
  onDownload: () => void;
}

function ContentCard(props: Props) {
  const { content, showDelete, showUnapprove, onUnapprove, onDelete, onApprove, onDownload } = props;
  const [openViewSocial, setOpenViewSocials] = useState<boolean>(false);

  const { useSocialHandlesMutationQuery } = useSocialHandles();
  const {
    mutate: getSocialHandles,
    data: socialHandles,
    isSuccess: isSuccessSocialHandles,
  } = useSocialHandlesMutationQuery(() => {}, () => {});

  return (
    <div className="group relative overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl">
      <Modal 
        open={openViewSocial && isSuccessSocialHandles} 
        onClose={() => setOpenViewSocials(false)}
        title={'Social Handles for Post'}
      >
        {isSuccessSocialHandles && socialHandles.map((handle, index) => (
          <div key={index} className="mb-3">
            <div className="rounded-lg bg-gray-50 p-4">
              <div className="font-semibold text-gray-900">{handle.social}</div>
              <div className="mt-1 text-gray-600">{handle.handle}</div>
            </div>
          </div>
        ))}
      </Modal>

      <div className="aspect-square overflow-hidden">
        <img 
          className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-110" 
          src={content.mediaUrl} 
          alt={`Content by ${content.userContentProfile.firstName}`}
        />
      </div>

      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 transition-opacity duration-300 group-hover:opacity-100">
        <div className="absolute bottom-0 w-full p-4 text-white">
          <h3 className="text-lg font-bold">
            {content.userContentProfile.firstName} {content.userContentProfile.lastName}
          </h3>
          <p className="mt-1 text-sm">{content.description}</p>
          
          <div className="mt-4 flex items-center justify-between">
            <div className="flex gap-3">
              {showUnapprove ? (
                <button onClick={onUnapprove} className="rounded-full bg-white/20 p-2 hover:bg-white/30">
                  <XIcon className="h-5 w-5 text-red-500" />
                </button>
              ) : (
                <button onClick={onApprove} className="rounded-full bg-white/20 p-2 hover:bg-white/30">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </button>
              )}
              
              {showDelete && (
                <button onClick={onDelete} className="rounded-full bg-white/20 p-2 hover:bg-white/30">
                  <TrashIcon className="h-5 w-5 text-red-500" />
                </button>
              )}
              
              <button onClick={onDownload} className="rounded-full bg-white/20 p-2 hover:bg-white/30">
                <DownloadIcon className="h-5 w-5 text-white" />
              </button>
            </div>

            <Button
              buttonWidth="auto"
              onClick={() => {
                getSocialHandles(content.userContentProfile.userId);
                setOpenViewSocials(true);
              }}
              label="Social Handles"
              className="bg-white/20 hover:bg-white/30 text-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function MerchantContentPage() {
  const authContext = useAuthContext();
  const { merchant } = authContext.getMerchantAndUser();

  const [filter, setFilter] = useState<{
    page: number;
    pageSize: number;
    approvedByMerchant: boolean;
  }>({
    page: 0,
    pageSize: 12,
    approvedByMerchant: true,
  });

  const {
    useMerchantPostsQuery,
    useMerchantPostsAcceptMutation,
    useMerchantPostsDeleteMutation,
    useMerchantPostsDenyMutation,
  } = useContent();
  
  const { data, isSuccess, refetch } = useMerchantPostsQuery(
    merchant.id, 
    filter.page, 
    filter.pageSize, 
    filter.approvedByMerchant
  );
  
  const acceptMutation = useMerchantPostsAcceptMutation(merchant.id, refetch);
  const deleteMutation = useMerchantPostsDeleteMutation(merchant.id, refetch);
  const denyMutation = useMerchantPostsDenyMutation(merchant.id, refetch);

  const Gallery = () => {
    const handleDownload = async (url: string) => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
        });
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const fileName = url.split('/').pop();
        if (fileName) {
          link.download = fileName;
          link.click();
        }
      } catch (error) {
        console.error('Download error:', error);
      }
    };

    return (
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {isSuccess && data.data.map((image) => (
          <ContentCard
            key={image.id}
            content={image}
            showDelete={!filter.approvedByMerchant}
            showUnapprove={filter.approvedByMerchant}
            onUnapprove={() => denyMutation.mutate(image.id)}
            onDelete={() => deleteMutation.mutate(image.id)}
            onApprove={() => acceptMutation.mutate(image.id)}
            onDownload={() => handleDownload(image.mediaUrl)}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold">Content</h1>
      
      <Tabs
        tabs={[
          {
            category: 'Approved',
            title: 'Approved',
            content: <Gallery />,
            onClick: () => setFilter({ ...filter, page: 0, approvedByMerchant: true }),
          },
          {
            category: 'Waiting Approval',
            title: 'Waiting Approval',
            content: <Gallery />,
            onClick: () => setFilter({ ...filter, page: 0, approvedByMerchant: false }),
          },
        ]}
      />

      {isSuccess && (
        <div className="py-4">
          <Pagination
            pageInfo={{
              pageNumber: filter.page + 1,
              pageSize: filter.pageSize,
              totalSize: data.totalSize,
              hasPrevious: data.hasPrevious,
              hasNext: data.hasNext,
            }}
            onChange={(info) =>
              setFilter({
                ...filter,
                page: Math.floor(info.pageNumber - 1),
                pageSize: info.pageSize,
              })
            }
          />
        </div>
      )}
    </div>
  );
}