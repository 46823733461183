import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../common/components/ui/button";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../../common/components/ui/input-otp";
import supabaseClient from "../../hooks/supabase";

export default function VerifyEmail() {
  const [params] = useSearchParams();
  const email = params.get("email");
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResending, setIsResending] = useState<boolean>(false);

  const verifyEmail = useCallback(async () => {
    setIsLoading(true);
    setError(undefined);

    if (!email || !verificationCode) {
      setError("Please enter the verification code");
      setIsLoading(false);
      return;
    }

    const { error: verifyError } = await supabaseClient.auth.verifyOtp({
      email,
      token: verificationCode,
      type: "email",
    });

    if (verifyError) {
      setError(verifyError.message);
    } else {
      navigate("/login");
    }

    setIsLoading(false);
  }, [email, verificationCode, navigate]);

  const resendVerification = useCallback(async () => {
    if (!email) return;

    setIsResending(true);
    setError(undefined);

    const { error: resendError } = await supabaseClient.auth.resend({
      email,
      type: "signup",
    });

    if (resendError) {
      setError(resendError.message);
    }

    setIsResending(false);
  }, [email]);

  return (
    <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
        <div className="absolute inset-0">
          <img
            src={require("../../assets/cover_for_login.jpg")}
            alt="Login cover"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-black/50" /> {/* Dark overlay */}
        </div>
        <div className="relative z-20 flex items-center text-lg font-medium">
          <img
            src={require("../../assets/qbon_logo.png")}
            alt="qbon-logo"
            className="h-8 w-8 mr-2"
          />
          Qbon
        </div>
        <div className="relative z-20 mt-auto">
          <blockquote className="space-y-2">
            <p className="text-lg">
              "This platform has revolutionized how we manage our business."
            </p>
            <footer className="text-sm">Sofia Davis</footer>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              Verify your email
            </h1>
            {email && (
              <p className="text-sm text-muted-foreground">
                We've sent a verification code to {email}
              </p>
            )}
          </div>

          <div className="grid gap-6">
            <div className="grid gap-4">
              {error && (
                <div className="text-sm font-medium text-destructive mx-auto">
                  {error}
                </div>
              )}
              <div className="grid gap-2 mx-auto">
                <InputOTP
                  maxLength={6}
                  value={verificationCode}
                  onChange={(value) => setVerificationCode(value)}
                  disabled={isLoading}
                >
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </div>
              <Button disabled={isLoading} onClick={verifyEmail}>
                {isLoading && (
                  <svg
                    className="mr-2 h-4 w-4 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                  </svg>
                )}
                Verify email
              </Button>
              <Button
                variant="outline"
                onClick={resendVerification}
                disabled={isResending}
              >
                {isResending && (
                  <svg
                    className="mr-2 h-4 w-4 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                  </svg>
                )}
                Resend code
              </Button>
              <Button variant="outline" onClick={() => navigate("/login")}>
                Back to login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
