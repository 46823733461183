import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Pagination, { PageInfo } from "../../common/library/Pagination";
import useMerchants from "../../hooks/merchants.hook";
import useAuthContext from "../../hooks/auth-context.hook";
import { PageSizing } from "../../types";
import LocationCard from "../../common/MerchantCard";
import { Button } from "../../common/components/ui/button";
import { Input as ShadcnInput } from "../../common/components/ui/input";
import { Card } from "../../common/components/ui/card";
import { Loader2, PlusIcon } from "lucide-react";

export default function Merchants() {
  const authContext = useAuthContext();
  const { user } = authContext.getUser();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState<string>("");
  const [query, setQuery] = useState<{
    contains?: string;
  }>({
    contains: undefined,
  });
  const [pageInfo, setPageInfo] = useState<PageSizing>({
    page: 0,
    pageSize: 10,
  });

  const { useUserMerchantsQuery } = useMerchants();
  const {
    data: merchants,
    isLoading,
  } = useUserMerchantsQuery(
    user.id,
    query.contains,
    pageInfo.page,
    pageInfo.pageSize
  );

  const setPage = useCallback(
    (changePage?: number, changePageSize?: number) => {
      const pageQuery = searchParams.get("page");
      const pageSizeQuery = searchParams.get("pageSize");

      const page =
        changePage !== undefined
          ? changePage
          : parseInt(pageQuery ? pageQuery : "0");
      const pageSize =
        changePageSize !== undefined
          ? changePageSize
          : parseInt(pageSizeQuery ? pageSizeQuery : "10");

      navigate({
        search: `?page=${page}&pageSize=${pageSize}`,
      });

      localStorage.setItem("merchantsPage", page.toString());
      localStorage.setItem("merchantsPageSize", pageSize.toString());
    },
    [searchParams, navigate]
  );

  useEffect(() => {
    const pageQuery = searchParams.get("page");
    const pageSizeQuery = searchParams.get("pageSize");

    if (!pageQuery && !pageSizeQuery) {
      setPage(0, 10);
    }

    const page = parseInt(pageQuery ? pageQuery : "0");
    const pageSize = parseInt(pageSizeQuery ? pageSizeQuery : "10");
    setPageInfo({
      page,
      pageSize,
    });
  }, [searchParams, setPage]);

  return (
    <Card className="p-6">
      <div className="flex flex-col space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="hidden sm:block text-2xl font-bold tracking-tight">My Merchants</h2>
          <div className="sm:w-auto w-full">
            <Button
              onClick={() => navigate('/create-merchant')}
              className="w-full sm:w-auto"
            >
              <PlusIcon className="mr-2 h-4 w-4" />
              Create Merchant
            </Button>
          </div>
        </div>

        <div className="w-full">
          <ShadcnInput
            placeholder="Search merchants by name, phone or email..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setPage(0);
                setQuery({
                  ...query,
                  contains: searchInput === "" ? undefined : searchInput,
                });
              }
            }}
            className="w-full"
          />
        </div>

        {isLoading ? (
          <div className="flex justify-center p-8">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
              {merchants.data.map((merchant) => (
                <LocationCard
                  key={merchant.id}
                  merchant={merchant}
                  onClick={() => authContext.setMerchant(merchant)}
                />
              ))}
            </div>
            
            <Pagination
              onChange={(info: PageInfo) => {
                const pageNumber = info.pageNumber - 1;
                const pageSize = info.pageSize;
                setPage(pageNumber, pageSize);
              }}
              pageInfo={{
                pageNumber: parseInt(merchants.pageNumber as any) + 1,
                pageSize: parseInt(merchants.pageSize as any),
                totalSize: merchants.totalSize,
                hasPrevious: merchants.hasPrevious,
                hasNext: merchants.hasNext,
              }}
            />
          </>
        )}
      </div>
    </Card>
  );
}
