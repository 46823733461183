import { useMemo } from 'react';
import { useMetrics } from '../../hooks/metrics.hook';
import useAuthContext from '../../hooks/auth-context.hook';
import { MetricType, secondaryColors } from './type';
import { Card } from "../components/ui/card";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

interface Props {
  metricType: MetricType;
  fromDate: Date;
  toDate: Date;
  primaryColor: string;
  couponId?: number;
  raffleId?: number;
}

export function MerchantMetricView({ primaryColor, metricType, couponId, raffleId, fromDate, toDate }: Props) {
  const auth = useAuthContext();
  const { merchant } = auth.getMerchantAndUser();

  const meta = () => {
    switch (metricType) {
      case ('STORE_FRONT_ARRIVED'): {
        return { title: 'Scanned into or arrived at store front', param: '' };
      }
      case ('LINK_CLICKED'): {
        return { title: 'Links clicked', param: '' };
      }
      case ('SOCIAL_SHARE'): {
        return { title: 'Social platforms users have shared to', param: '' };
      }
      case ('COUPON_CLAIMED'): {
        return { title: 'Qbon(s) rewarded to users count', param: 'couponMetricValue=CLAIMED' };
      }
      case ('COUPON_REDEEMED'): {
        return { title: 'Qbon(s) redeemed by users count', param: 'couponMetricValue=REDEEMED' };
      }
      case ('COUPON_CREATED'): {
        return { title: 'Qbons created count', param: 'couponMetricValue=CREATED' };
      }
      case ('COUPON_VIEWED'): {
        return { title: 'View count for Qbon', param: 'couponMetricValue=VIEWED' };
      }
      case ('RAFFLE_ENTERED'): {
        return { title: 'Users entering raffle count', param: 'raffleMetricValue=ENTERED' };
      }
      case ('CONTENT_POSTED'): {
        return { title: 'Count of content posted to your feed', param: 'contentMetricValue=CREATED' };
      }
      case ('MERCHANT_CREATED'): {
        return { title: 'Merchants created', param: 'systemMetricValue=MERCHANT_CREATED' };
      }
      case ('USER_CREATED'): {
        return { title: 'Users created', param: 'systemMetricValue=MERCHANT_CREATED' };
      }
      default: {
        return { title: 'No metric available', param: '' };
      }
    }
  };

  const couponIdQueryParam = couponId ? `couponId=${couponId}` : '';
  const raffleIdQueryParam = raffleId ? `raffleId=${raffleId}` : '';

  const { useMerchantMetricsQuery } = useMetrics();
  const { data, isSuccess, isLoading } = useMerchantMetricsQuery(
    merchant.id,
    metricType,
    fromDate,
    toDate,
    [meta().param, couponIdQueryParam, raffleIdQueryParam],
  );

  function extractUniqueKeys(obj: any): string[] {
    const uniqueKeys = new Set<string>();

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && key.toLowerCase() !== 'date') {
        uniqueKeys.add(key);
      }
    }

    return Array.from(uniqueKeys);
  }

  const lines = useMemo(() => {
    if (isSuccess) {
      const keys = extractUniqueKeys(data.timeseries[0]);
      return (
        keys.map((key, index) => (
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            stroke={index === 0 ? primaryColor : secondaryColors[index % 10]}
            strokeWidth={2}
            dot={false}
          />
        ))
      );
    }
    return null;
  }, [data, isSuccess, primaryColor]);

  if (metricType === 'COUPON_VIEWED' && !couponId) {
    return (<>Can not display this metric</>);
  }

  if (metricType === 'RAFFLE_ENTERED' && !raffleId) {
    return (<>Can not display this metric</>);
  }

  return (
    <Card>
      <div className="p-4">
        <div className="font-bold mb-4">{meta().title}</div>
        {!isLoading && (
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={isSuccess ? data.timeseries : []}>
                <CartesianGrid strokeDasharray="3 3" className="stroke-muted" />
                <XAxis
                  dataKey="date"
                  stroke="#888888"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                />
                <YAxis
                  stroke="#888888"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={(value) => `${value}`}
                />
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className="rounded-lg border bg-background p-2 shadow-sm">
                          <div className="grid grid-cols-2 gap-2">
                            {payload.map((p) => (
                              <div key={p.dataKey}>
                                <p className="text-[0.70rem] uppercase text-muted-foreground">
                                  {p.dataKey}
                                </p>
                                <p className="text-sm font-bold">{p.value}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    }
                    return null
                  }}
                />
                {lines}
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </Card>
  );
}