import { CheckCircle } from "lucide-react"
import Button from "./Button"

export interface PricingBoxProps {
  loading: boolean
  title: string
  price: string
  rate: string
  rateDisclosure?: string
  description: string
  features: string[]
  onPurchase: () => void
}

export function PricingBox(props: PricingBoxProps) {
  const {
    loading,
    price,
    rate,
    description,
    features,
    onPurchase,
    title,
    rateDisclosure,
  } = props

  return (
    <div className="flex flex-col items-center justify-center mt-16 space-y-8 lg:flex-row lg:items-stretch lg:space-x-8 lg:space-y-0">
      <div className="flex flex-col w-full max-w-sm p-8 space-y-6 bg-white/75 backdrop-blur-sm rounded-xl border border-gray-200 shadow-sm transition-all hover:shadow-md">
        <div className="space-y-2 text-center">
          <h3 className="text-2xl font-semibold tracking-tight">{title}</h3>
          <div className="flex items-baseline justify-center gap-1">
            <span className="text-3xl font-bold tracking-tight">${price}</span>
            <span className="text-sm font-medium text-gray-500">/{rate}</span>
          </div>
          {rateDisclosure && (
            <p className="text-sm text-gray-500">*{rateDisclosure}</p>
          )}
        </div>
        
        <div className="border-y border-gray-200 py-4">
          <p className="text-sm text-gray-600 text-center">{description}</p>
        </div>

        <ul className="space-y-3">
          {features.map((feature, i) => (
            <li key={i} className="flex items-center gap-2">
              <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
              <span className="text-sm text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>

        <div className="pt-2">
          <Button 
            variant="primary"
            label="Subscribe" 
            loading={loading} 
            buttonWidth="full" 
            onClick={onPurchase}
          />
        </div>
      </div>
    </div>
  )
}
