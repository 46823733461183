import { MenuIcon } from "@heroicons/react/outline";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { createContext, useState } from "react";
import MenuItem, { MenuItemProps } from "./MenuItem";
import { cn } from "../lib/utils";
import { Button } from "./components/ui/button";
import { ScrollArea } from "./components/ui/scroll-area";

export interface DashboardProps {
  menuItems: MenuItemProps[];
  logo: React.ReactNode;
  children: React.ReactNode;
  barItems?: React.ReactNode;
}

export const DashboardContext = createContext<
  | {
      setHideSidebar: any;
    }
  | undefined
>(undefined);

export default function Dashboard(props: DashboardProps) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [hideSidebar, setHideSidebar] = useState<boolean>(false);
  const { menuItems, logo, children, barItems } = props;

  return (
    <div className="relative flex min-h-screen">
      {/* Desktop Sidebar */}
      {!hideSidebar && (
        <aside className="hidden w-[280px] shrink-0 border-r border-border bg-background lg:block">
          <ScrollArea className="h-full py-6">
            <div className="flex items-center justify-center px-6">
              {logo}
            </div>
            <nav className="space-y-1 px-3 pt-6">
              {menuItems.map((menuItem, index) => (
                <MenuItem
                  key={index}
                  label={menuItem.label}
                  picked={menuItem.picked}
                  href={menuItem.href}
                  icon={menuItem.icon}
                  subItems={menuItem.subItems}
                  openSubItems={menuItem.openSubItems}
                  onClick={menuItem.onClick}
                  loading={menuItem.loading}
                  statusIcon={menuItem.statusIcon}
                />
              ))}
            </nav>
          </ScrollArea>
        </aside>
      )}

      {/* Mobile Sidebar */}
      <aside
        className={cn(
          "fixed inset-y-0 left-0 z-50 w-full border-r border-border bg-background transition-transform duration-300 lg:hidden",
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        )}
      >
        <ScrollArea className="h-full">
          <div className="flex items-center justify-between px-6 py-4">
            {logo}
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setSidebarOpen(false)}
            >
              <ChevronLeftIcon className="h-6 w-6" />
            </Button>
          </div>
          <nav className="space-y-1 px-3">
            {menuItems.map((menuItem, index) => (
              <MenuItem
                key={index}
                label={menuItem.label}
                picked={menuItem.picked}
                href={menuItem.href}
                icon={menuItem.icon}
                subItems={menuItem.subItems}
                openSubItems={menuItem.openSubItems}
                onClick={() => {
                  menuItem.onClick?.();
                  setSidebarOpen(false);
                }}
                loading={menuItem.loading}
                statusIcon={menuItem.statusIcon}
              />
            ))}
          </nav>
        </ScrollArea>
      </aside>

      {/* Main Content */}
      <main className="flex-1">
        <header className="flex h-14 items-center justify-between border-b border-border px-4 lg:px-8">
          <Button
            variant="ghost"
            size="icon"
            className="lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <MenuIcon className="h-5 w-5" />
          </Button>
          <div className="ml-auto">{barItems}</div>
        </header>
        <div className="h-[calc(100vh-3.5rem)] overflow-auto px-4 py-6 lg:px-8">
          <DashboardContext.Provider value={{ setHideSidebar }}>
            {children}
          </DashboardContext.Provider>
        </div>
      </main>
    </div>
  );
}
