import React, { useState } from 'react';
import { useMetrics } from '../../../hooks/metrics.hook';
import useAuthContext from '../../../hooks/auth-context.hook';
import { PageInfo } from '../../../common/library/Pagination';
import Table, { TableColumn } from '../../../common/library/Table';
import { UserArrivalLog } from '../../../models/arrival-logs';
import { MetricView } from '../../../common/metrics/MetricView';
import Tabs from '../../../common/library/Tabs';

export default function MerchantMetricsPage() {
  const { merchant } = useAuthContext().getMerchantAndUser();
  const [pageInfo, setPageInfo] = useState<{ pageNumber: number, pageSize: number }>({
    pageNumber: 0,
    pageSize: 10,
  });

  const { useMerchantArrivalLogsQuery } = useMetrics();
  const {
    data: arrivalLogs,
    isPending: isPendingArrivalLogs,
    isSuccess: isSuccessArrivalLogs,
  } = useMerchantArrivalLogsQuery(merchant.id, pageInfo.pageNumber, pageInfo.pageSize);

  const columns: TableColumn<UserArrivalLog>[] = [
    {
      className: 'hidden md:table-cell',
      label: 'Email',
      format: (val) => <>{val.user?.email}</>,
    },
    {
      label: 'First Name',
      format: (val) => <>{val.user?.firstName}</>,
    },
    {
      label: 'Last Name',
      format: (val) => <>{val.user?.lastName}</>,
    },
    {
      label: 'Shared Arrival',
      format: (val) => <>{val.shared ? 'Yes' : 'No'}</>,
    },
    {
      className: 'hidden md:table-cell',
      label: 'Date',
      format: (val) => <>{new Date(val.createdDate).toLocaleDateString()} {new Date(val.createdDate).toLocaleTimeString()}</>,
    },
  ];


  return (
    <>
      <h1 className="font-bold text-xl pb-2 md:px-0 px-3">Metrics</h1>
      <div className="w-full">
        <Tabs
          tabs={[
            {
              category: 'Live Metrics',
              title: 'Live Metrics',
              content: (
                <>
                  <div className="md:flex md:justify-between pt-1.5 space-y-4 md:space-y-0">
                    <div className="w-full">
                      <MetricView isSystemMetrics={false} defaultMetricType={'STORE_FRONT_ARRIVED'}
                                  primaryColor={'#5DA370'} />
                    </div>
                    <div className="md:pl-3 w-full">
                      <MetricView isSystemMetrics={false} defaultMetricType={'COUPON_CLAIMED'}
                                  primaryColor={'#262666'} />
                    </div>
                  </div>
                  <div className="md:flex md:justify-between mt-4 space-y-4 md:space-y-0">
                    <div className="w-full">
                      <MetricView isSystemMetrics={false} defaultMetricType={'COUPON_REDEEMED'}
                                  primaryColor={'#E62929'} />
                    </div>
                    <div className="md:pl-2 w-full">
                      <MetricView isSystemMetrics={false} defaultMetricType={'SOCIAL_SHARE'} primaryColor={'#BA29E6'} />
                    </div>
                  </div>
                </>
              ),
            },
            {
              category: 'Arrivals',
              title: 'Taps and Scans',
              content: (
                <>
                  <Table
                    loading={isPendingArrivalLogs}
                    columns={columns}
                    data={isSuccessArrivalLogs ? arrivalLogs.data : []}
                    pagination={{
                      pageInfo: {
                        pageNumber: parseInt(pageInfo.pageNumber as any) + 1,
                        pageSize: parseInt(pageInfo.pageSize as any),
                        totalSize: isSuccessArrivalLogs ? arrivalLogs.totalSize : 0,
                        hasNext: isSuccessArrivalLogs ? arrivalLogs.hasNext : false,
                        hasPrevious: isSuccessArrivalLogs ? arrivalLogs.hasPrevious : false,
                      },
                      onChange: (info: PageInfo) => {
                        setPageInfo({
                          pageNumber: info.pageNumber - 1,
                          pageSize: info.pageSize,
                        });
                      },
                    }}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}
