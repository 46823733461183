import { useState } from "react";
import Input from "./Input";
import Button from "./Button";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { LinkIcon } from "@heroicons/react/outline";
import { motion, AnimatePresence } from "framer-motion";

interface Props {
  onCreate: (label: string, url: string) => void;
}

function AddCustomLinkButton({ onCreate }: Props) {
  const [label, setLabel] = useState("");
  const [url, setUrl] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = () => {
    if (label && url) {
      onCreate(label, url);
      setLabel("");
      setUrl("");
      setIsEditing(false);
    }
  };

  return (
    <AnimatePresence mode="wait">
      {!isEditing ? (
        <motion.div
          key="add-button"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.1 }}
        >
          <Button
            onClick={() => setIsEditing(true)}
            label="Add Custom Link"
            icon={<LinkIcon className="h-4 w-4" />}
            buttonWidth="full"
          />
        </motion.div>
      ) : (
        <motion.div
          key="form"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.1 }}
          className="flex flex-col sm:flex-row gap-3"
        >
          <div className="flex-1 flex gap-3">
            <Input
              type="text"
              placeholder="Label"
              value={label}
              onChange={setLabel}
            />
            <Input
              type="text" 
              placeholder="URL"
              value={url}
              onChange={setUrl}
            />
          </div>
          <div className="flex gap-2">
            <Button
              onClick={handleSubmit}
              className="text-white hover:bg-green-600"
              icon={<CheckIcon className="h-4 w-4" />}
              aria-label="Save"
            />
            <Button
              onClick={() => setIsEditing(false)}
              className="text-white hover:bg-red-600" 
              icon={<XIcon className="h-4 w-4" />}
              aria-label="Cancel"
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default AddCustomLinkButton;
