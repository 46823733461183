import { Combobox } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classNames from "classnames";

export interface AutocompleteBoxProps<T> {
  options: T[];
  labelKey?: keyof T;
  format?: (value: T) => string;
  idKey: keyof T;
  value: T | undefined;
  label?: string;
  onSelected: (value: T | undefined) => void;
  onInputChange: (value: string) => void;
  disabled?: boolean;
}

export default function AutocompleteBox<T>(props: AutocompleteBoxProps<T>) {
  const {
    options,
    labelKey,
    idKey,
    value,
    label,
    onSelected,
    format,
    onInputChange,
    disabled,
  } = props;

  return (
    <div className="w-full">
      {label && (
        <div className="flex items-center mb-1">
          <label
            htmlFor={label}
            className={classNames(
              "block text-sm font-semibold tracking-wide",
              disabled ? "text-gray-400" : "text-gray-700"
            )}
          >
            {label}
          </label>
        </div>
      )}
      <Combobox disabled={disabled} value={value} onChange={onSelected}>
        <div className="relative">
          <div className="relative w-full text-left bg-white rounded-lg shadow-sm ring-1 ring-gray-200 focus-within:ring-2 focus-within:ring-red-500 transition-all duration-200">
            <Combobox.Input
              className="w-full h-11 px-4 py-2 text-sm bg-transparent border-none rounded-lg text-gray-900 placeholder-gray-400 focus:outline-none"
              displayValue={(selected: T | undefined) => {
                if (value !== undefined && format) {
                  return format(value);
                }
                if (value !== undefined && labelKey !== undefined) {
                  return (value as any)[labelKey];
                }
                return "";
              }}
              onChange={(event) => onInputChange(event.target.value as string)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-3">
              <SelectorIcon
                className="w-5 h-5 text-gray-400 hover:text-gray-600 transition-colors"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>

          <Combobox.Options className="absolute w-full py-2 mt-2 overflow-auto text-base bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 max-h-60 focus:outline-none sm:text-sm z-40">
            {options.length === 0 ? (
              <div className="px-4 py-3 text-sm text-gray-500 italic">
                No results found
              </div>
            ) : (
              options.map((option: T) => (
                <Combobox.Option
                  key={(option as any)[idKey]}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-pointer select-none py-3 pl-10 pr-4",
                      active ? "bg-red-50 text-red-900" : "text-gray-900",
                      "transition-colors duration-100 ease-in-out"
                    )
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected ? "font-medium" : "font-normal"
                        )}
                      >
                        {format !== undefined
                          ? format(option)
                          : (option as any)[labelKey]}
                      </span>
                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 left-0 flex items-center pl-3",
                            active ? "text-red-900" : "text-red-600"
                          )}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
}
