import React, { useCallback, useState } from "react";
import ImageCropModal from "../ImageCropper";
import { Card } from "../../components/ui/card";
import { Camera } from "lucide-react";

export default function CoverImageUpload({
  imageUrl,
  onSave,
  saving,
}: {
  imageUrl?: string;
  onSave?: (file: Blob) => void;
  saving: boolean;
}) {
  const [state, setState] = useState<{
    file: Blob | undefined;
    imagePreviewUrl: string | undefined;
    showCropModal: boolean;
  }>({
    file: undefined,
    imagePreviewUrl: imageUrl,
    showCropModal: false,
  });

  const photoUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setState((prevState) => ({
        ...prevState,
        file,
        imagePreviewUrl: url,
        showCropModal: true,
      }));
    }
  }, []);

  const handleCropComplete = useCallback(
    (croppedBlob: Blob | null) => {
      if (croppedBlob) {
        if (onSave) {
          onSave(croppedBlob);
        }
        setState((prevState) => ({
          ...prevState,
          file: croppedBlob,
          imagePreviewUrl: URL.createObjectURL(croppedBlob),
          showCropModal: false,
        }));
      }
    },
    [onSave]
  );

  return (
    <Card className="relative group overflow-hidden rounded-lg border border-border">
      <label className="block w-full cursor-pointer">
        <div className="relative w-full h-[200px] md:h-[300px]">
          <img
            className="h-full w-full object-cover object-center transition-transform duration-300 group-hover:scale-105"
            alt="Cover"
            src={state.imagePreviewUrl || ""}
          />
          <div className="absolute inset-0 bg-black/40 opacity-60 md:opacity-0 md:group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
            <Camera className="w-8 h-8 md:w-10 md:h-10 text-white" />
            <span className="ml-2 text-white text-sm md:text-base">Tap to change cover</span>
          </div>
          {saving && (
            <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
              <div className="animate-spin rounded-full h-6 w-6 md:h-8 md:w-8 border-b-2 border-white"></div>
            </div>
          )}
        </div>
        <input
          id="photo-upload-cover"
          accept="image/*"
          type="file"
          capture="environment"
          className="hidden"
          onChange={photoUpload}
        />
      </label>
      {state.showCropModal && state.imagePreviewUrl && (
        <ImageCropModal
          src={state.imagePreviewUrl}
          aspect={[16, 9]}
          onComplete={handleCropComplete}
          onClose={() =>
            setState((prevState) => ({ ...prevState, showCropModal: false }))
          }
        />
      )}
    </Card>
  );
}
