import { useNavigate } from "react-router";
import { useCallback, useState } from "react";
import { Button } from "../../common/components/ui/button";
import { Input } from "../../common/components/ui/input";
import supabaseClient from "../../hooks/supabase";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const resetPassword = useCallback(async () => {
    setIsLoading(true);
    setError(undefined);

    if (!email) {
      setError("Please enter your email address");
      setIsLoading(false);
      return;
    }

    const { error: resetError } =
      await supabaseClient.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/new-password`,
      });

    if (resetError) {
      setError(resetError.message);
    } else {
      setEmailSent(true);
    }

    setIsLoading(false);
  }, [email]);

  return (
    <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
        <div className="absolute inset-0">
          <img
            src={require("../../assets/cover_for_login.jpg")}
            alt="Login cover"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-black/50" /> {/* Dark overlay */}
        </div>
        <div className="relative z-20 flex items-center text-lg font-medium">
          <img
            src={require("../../assets/qbon_logo.png")}
            alt="qbon-logo"
            className="h-8 w-8 mr-2"
          />
          Qbon
        </div>
        <div className="relative z-20 mt-auto">
          <blockquote className="space-y-2">
            <p className="text-lg">
              "This platform has revolutionized how we manage our business."
            </p>
            <footer className="text-sm">Sofia Davis</footer>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              Reset password
            </h1>
            <p className="text-sm text-muted-foreground">
              Enter your email to reset your password
            </p>
          </div>

          {emailSent ? (
            <div className="flex flex-col gap-4">
              <p className="text-center text-sm text-muted-foreground">
                Check your email for a password reset link. If you don't see it,
                check your spam folder.
              </p>
              <Button variant="outline" onClick={() => navigate("/login")}>
                Back to login
              </Button>
            </div>
          ) : (
            <div className="grid gap-6">
              <div className="grid gap-4">
                {error && (
                  <div className="text-sm font-medium text-destructive">
                    {error}
                  </div>
                )}
                <div className="grid gap-2">
                  <Input
                    id="email"
                    placeholder="name@example.com"
                    type="email"
                    autoCapitalize="none"
                    autoComplete="email"
                    autoCorrect="off"
                    disabled={isLoading}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <Button disabled={isLoading} onClick={resetPassword}>
                  {isLoading && (
                    <svg
                      className="mr-2 h-4 w-4 animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                    </svg>
                  )}
                  Send reset link
                </Button>
              </div>
              <Button variant="outline" onClick={() => navigate("/login")}>
                Back to login
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
