import * as React from "react"
import * as TabsPrimitive from "@radix-ui/react-tabs"
import { cn } from "../../lib/utils"

export interface TabProps {
  category: string;
  title: string;
  content: React.ReactNode;
  onClick?: () => void;
  hideOnMobile?: boolean;
}

export interface TabsProps {
  tabs: TabProps[];
}

export default function Tabs({ tabs }: TabsProps) {
  return (
    <TabsPrimitive.Root
      defaultValue={tabs[0]?.title}
      className="w-full rounded-2xl bg-white pt-4"
    >
      <TabsPrimitive.List className="flex h-10 items-center gap-2">
        {tabs.map((tab, idx) => (
          <TabsPrimitive.Trigger
            key={idx}
            value={tab.title}
            onClick={tab.onClick}
            className={cn(
              "inline-flex items-center justify-center whitespace-nowrap px-4 py-5 text-sm font-medium ring-offset-white transition-all duration-300 ease-in-out",
              "focus:outline-none", 
              "border-b-2 border-transparent relative mb-[16px]",
              "data-[state=active]:border-[#fb3310] data-[state=active]:font-bold",
              "text-gray-700 hover:bg-gray-100",
              tab.hideOnMobile && "hidden md:block"
            )}
          >
            {tab.title}
          </TabsPrimitive.Trigger>
        ))}
      </TabsPrimitive.List>
      <div className="border-b border-gray-100" />
      {tabs.map((tab, idx) => (
        <TabsPrimitive.Content
          key={idx}
          value={tab.title}
          className="p-4 bg-white rounded-b-lg focus:outline-none"
        >
          {tab.content}
        </TabsPrimitive.Content>
      ))}
    </TabsPrimitive.Root>
  );
}