import { useProviderAuth } from "../hooks/auth.hook";
import { createContext } from "react";
import { Session } from "@supabase/supabase-js";
import { User } from "../models/users";
import { Merchant } from "../models/merchant";
import { ReactNode } from "react";

export type AuthContextType = {
  user: User | undefined;
  session: Session | null;
  setMerchant: (merchant: Merchant) => void;
  exitMerchant: () => void;
  updateMerchant: (merchant: Merchant) => void;
  merchant: Merchant | undefined;
  isSessionLoading: boolean;
  refreshUserSession: () => void;
  logOut: () => void;
  getMerchantAndUser: () => { merchant: Merchant; user: User };
  getUser: () => { user: User };
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const auth = useProviderAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
