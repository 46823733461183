import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import Spinner from "./Spinner"
import { cn } from "../../lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium transition-all duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 shadow-sm hover:shadow-md active:scale-95",
  {
    variants: {
      variant: {
        primary: "bg-gradient-to-r from-[#fb3310] to-red-600 hover:from-red-600 hover:to-red-700 text-white border-transparent",
        secondary: "bg-gradient-to-r from-gray-100 to-gray-200 hover:from-gray-200 hover:to-gray-300 text-gray-800",
        warning: "bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-600 text-white border-transparent", 
        danger: "bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 text-white border-transparent",
        activate: "bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white border-transparent",
        outline: "border-2 border-gray-300 bg-white/80 backdrop-blur-sm text-gray-700 hover:bg-gray-50 hover:border-gray-400",
        free: "border-transparent hover:bg-gray-100/80 backdrop-blur-sm"
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 px-3 py-1.5 text-xs",
        lg: "h-11 px-8 py-2.5 text-base",
        icon: "h-10 w-10",
      },
      width: {
        auto: "w-auto",
        full: "w-full"
      },
      rounded: {
        default: "rounded-lg",
        full: "rounded-full"
      }
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
      width: "auto",
      rounded: "default"
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  label?: string
  icon?: React.ReactNode
  image?: React.ReactNode
  loading?: boolean
  alwaysShowLabel?: boolean
  buttonWidth?: "auto" | "full" // Keep old prop for backwards compatibility
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, width, buttonWidth, rounded, asChild = false, label, icon, image, loading, alwaysShowLabel = false, disabled, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"

    // Use buttonWidth prop if provided, otherwise fall back to width prop
    const finalWidth = buttonWidth || width

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, width: finalWidth, rounded, className }))}
        ref={ref}
        disabled={disabled || loading}
        {...props}
      >
        {!loading && (
          <div className="m-auto flex items-center gap-2">
            {icon && <div className="h-4 w-4">{icon}</div>}
            {image && <div>{image}</div>}
            {label && (
              <span className={cn("font-medium", !alwaysShowLabel && icon ? "hidden md:inline" : "inline")}>
                {label}
              </span>
            )}
          </div>
        )}
        {loading && (
          <div className="m-auto">
            <Spinner width={6} height={6} color="red" />
          </div>
        )}
      </Comp>
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
export default Button
