import Toggle from "../../../common/library/Toggle";
import Combobox from "../../../common/library/Combobox";
import { feetToMetersTextToInt, metersToText } from "../../../util";
import { User } from "../../../models/users";
import { Merchant } from "../../../models/merchant";
import useMerchants from "../../../hooks/merchants.hook";
import TutorialDescriptionCard from "./TutorialDescriptionCard";

interface Props {
  user: User;
  merchant: Merchant;
}

export default function GeofenceTutorial({
  merchant: incomingMerchant,
}: Props) {
  const { useMerchantQuery, useUpdateStoreFrontSettingsMutation } =
    useMerchants();
  const { data: merchant, refetch: refetchMerchant } = useMerchantQuery(
    incomingMerchant.id,
    incomingMerchant
  );

  const updateStoreFrontSettings = useUpdateStoreFrontSettingsMutation(
    incomingMerchant.id,
    (res) => {
      refetchMerchant();
    },
    (error) => {}
  );

  return (
    <>
      {merchant && (
        <div className="h-full flex flex-col">
          <TutorialDescriptionCard
            title="Merchant Geofence"
            description="Want us to verify the users location when they scan your QR Code? We will not allow users to access your storefront unless they are in your business, this prevents scanning your QR Code outside of your business. Not recommended for E-Commerce."
            icon={
              <>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* Location Pin */}
                  <path
                    d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2Z"
                    fill="#FB3310"
                    stroke="#4C1D95"
                    strokeWidth="1.5"
                  />

                  {/* Inner Circle */}
                  <circle cx="12" cy="9" r="3" fill="#7C3AED" />
                  {/* Geofence Rings */}
                  <circle
                    cx="12"
                    cy="9"
                    r="6"
                    stroke="#34D399"
                    strokeWidth="1.5"
                    strokeDasharray="2 2"
                    fill="none"
                  />
                  <circle
                    cx="12"
                    cy="9"
                    r="4.5"
                    stroke="#34D399"
                    strokeWidth="1.5"
                    strokeDasharray="2 2"
                    fill="none"
                  />
                </svg>
              </>
            }
          />
          <div className="flex-1 flex items-center justify-center gap-3">
            <Toggle
              enabled={
                merchant.storeFrontSettings?.enableVerifyQRScanLocation
                  ? merchant.storeFrontSettings.enableVerifyQRScanLocation
                  : false
              }
              onChange={(val) => {
                if (merchant.storeFrontSettings) {
                  updateStoreFrontSettings.mutate({
                    ...merchant.storeFrontSettings,
                    enableVerifyQRScanLocation: val,
                  });
                } else {
                  updateStoreFrontSettings.mutate({
                    enableArriveWithLocation: false,
                    arriveAtDistanceMeters: 10,
                    enableVerifyQRScanLocation: val,
                    verifyQRScanDistanceMeters: 0,
                  });
                }
              }}
            />
            <span className="text-lg font-medium">
              {merchant.storeFrontSettings?.enableVerifyQRScanLocation
                ? "Geofence enabled"
                : "Geofence disabled"}
            </span>
          </div>

          {merchant.storeFrontSettings?.verifyQRScanDistanceMeters && (
            <div className="flex-1 flex items-center justify-center">
              <Combobox
                label="Maximum distance to allow QR scan for users"
                options={[
                  "15 Meters, Approximately 50 Feet",
                  "30 Meters, Approximately 100 Feet",
                  "60 Meters, Approximately 200 Feet",
                  "150 Meters, Approximately 500 Feet",
                  "230 Meters, Approximately 750 Feet",
                  "300 Meters, Approximately 1000 Feet",
                ]}
                value={metersToText(
                  merchant.storeFrontSettings?.verifyQRScanDistanceMeters
                )}
                onChange={(val: string) => {
                  if (merchant.storeFrontSettings) {
                    updateStoreFrontSettings.mutate({
                      ...merchant.storeFrontSettings,
                      verifyQRScanDistanceMeters: feetToMetersTextToInt(val),
                    });
                  } else {
                    updateStoreFrontSettings.mutate({
                      enableArriveWithLocation: true,
                      verifyQRScanDistanceMeters: feetToMetersTextToInt(val),
                      enableVerifyQRScanLocation: false,
                      arriveAtDistanceMeters: 0,
                    });
                  }
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
